import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import UserDataProvider from "../../context/UserDataProvider";
import { useState } from "react";
import { EXTERNAL_ROUTES, ROUTES } from "../../helpers/routes";
import axios from "../../api/axios";
import ProfilePictureLoader from "../../context/ProfilePictureLoader";
import ImageBox from "../../components/ImageBox";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ApproveOrRejectInput from "../../context/ApproveOrRejectInput";
import PopupDialog from "../../components/PopupDialog";

const PersonalInfo = () => {
  const theme = useTheme();
  const { userId } = useParams();
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [openDataDialog, setOpenDataDialog] = useState(false);
  const colors = tokens(theme.palette.mode);
  const [reason, setReason] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    workingCity: "",
  });

  const [error, setError] = useState(""); // State for storing error message

  const [errors, setErrors] = useState({}); // State for storing error messages

  const validateFormData = (formData) => {
    const newErrors = {};

    setErrors(newErrors);
  };

  const handleSubmitDataInfo = () => {
    validateFormData(formData);
    console.log(errors);
    if (
      Object.keys(errors).length !== 0 &&
      Object.values(errors).every((error) => error !== "")
    ) {
      // If there are errors and all error messages are not empty strings, return early without submitting data
      console.log("Validation failed. Cannot submit data.");
      return;
    }

    const updatedPersonalInfoData = {
      ...formData,
      working_city: formData.workingCity !== "" && formData.workingCity,
    };

    console.log("Updated personal Info data:", updatedPersonalInfoData);

    setFormData({
      workingCity: "",
    });

    // Send the updated data to the server
    sendDataToServer(updatedPersonalInfoData);
  };

  const sendDataToServer = (updatedData) => {
    // Get token from local storage
    const token = localStorage.getItem("token");

    // Define the data to be sent to the server
    const postData = {
      ...updatedData,
    };

    // Set the authorization header with the token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Adăugăm token-ul în antetul de autorizare
      },
    };
    console.log(postData);
    // Send the updated data to the server
    axios
      .post(`${EXTERNAL_ROUTES.USER}/${userId}`, postData, config)
      .then((response) => {
        window.location.reload();
        console.log("Data successfully sent:", response.data);
      })
      .catch((error) => {
        setError(error?.response?.data?.error?.detail);
      });
  };

  const handleChange = (e, field) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    // Clear the error message when user starts typing in the field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const handleAccept = async () => {
    // Call the approveInput method from ApproveOrRejectInput component
    try {
      const response = await ApproveOrRejectInput({ userId }).approveInput(
        "profile_picture"
      );

      console.log("Input approved:", response);

      setOpenApproveDialog(true);
    } catch (error) {
      console.error("Error approving input:", error);
      alert("Error: " + error);
    }
  };

  const handleAcceptPersonalInfo = async () => {
    // Call the approveInput method from ApproveOrRejectInput component
    try {
      const response = await ApproveOrRejectInput({ userId }).approveInput(
        "personal_data"
      );

      console.log("Input approved:", response);

      setOpenApproveDialog(true);
    } catch (error) {
      console.error("Error approving input:", error);
      alert("Error: " + error);
    }
  };

  const handleReject = async () => {
    // Call the rejectInputWithReason method from ApproveOrRejectInput component
    if (reason.trim() !== "") {
      try {
        const response = await ApproveOrRejectInput({
          userId,
        }).rejectInputWithReason("profile_picture", reason);
        console.log("Input rejected with reason:", response);

        setOpenRejectDialog(true);
      } catch (error) {
        console.error("Error rejecting input:", error);
        alert("Error: " + error);
      }
    } else {
      alert("Te rog să introduci un motiv pentru refuz.");
    }
  };

  const handleRejectPersonalInfo = async () => {
    // Call the rejectInputWithReason method from ApproveOrRejectInput component
    if (reason.trim() !== "") {
      try {
        const response = await ApproveOrRejectInput({
          userId,
        }).rejectInputWithReason("personal_data", reason);
        console.log("Input rejected with reason:", response);

        setOpenRejectDialog(true);
      } catch (error) {
        console.error("Error rejecting input:", error);
        alert("Error: " + error);
      }
    } else {
      alert("Te rog să introduci un motiv pentru refuz.");
    }
  };

  const handleGoBack = (userId) => {
    navigate(`${ROUTES.ONBOARDING}/${userId}`);
  };

  const handleCloseDialog = () => {
    // Închide dialogul de succes
    setOpenApproveDialog(false);
    window.location.reload();
  };

  const handleCloseDialogReject = () => {
    // Închide dialogul de succes
    setOpenRejectDialog(false);
    window.location.reload();
  };

  const handleCloseDataDialog = () => {
    // Închide dialogul de succes
    setOpenDataDialog(false);
    window.location.reload();
  };

  return (
    <UserDataProvider userId={userId} setError={setError}>
      {(personalInfoData) => (
        <Box m="20px">
          <PopupDialog
            header="Date actualizate!"
            content="Datele au fost actualizate cu succes!"
            open={openDataDialog}
            onClose={handleCloseDataDialog}
          ></PopupDialog>
          <PopupDialog
            header="Document aprobat!"
            content="Documentul a fost aprobat cu succes!"
            open={openApproveDialog}
            onClose={handleCloseDialog}
          ></PopupDialog>
          <PopupDialog
            header="Document refuzat!"
            content="Documentul a fost refuzat!"
            open={openRejectDialog}
            onClose={handleCloseDialogReject}
          ></PopupDialog>
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header
              title="Date personale sofer"
              subtitle={`Date personale pentru user: ${userId}`}
            />
            <Box>
              <Button
                sx={{
                  backgroundColor: colors.primary[700],
                  color: colors.orangeAccent[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                }}
                onClick={() => handleGoBack(userId)}
              >
                <ArrowBackOutlinedIcon sx={{ mr: "10px" }} />
                Back
              </Button>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {/* Display error message */}
            {error && (
              <Typography color="error" variant="body1">
                Error message: {error}
              </Typography>
            )}{" "}
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
          >
            <Box
              gridColumn="span 4"
              gridRow="span 4"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                gridColumn="span 3"
                gridRow="span 2"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding="10px"
              >
                <ProfilePictureLoader userId={userId}>
                  {(profilePicture) =>
                    profilePicture ? (
                      <ImageBox imageUrl={profilePicture} />
                    ) : (
                      <Typography
                        variant="h5"
                        color={colors.orangeAccent[500]}
                        sx={{ mt: "15px" }}
                      >
                        No picture
                      </Typography>
                    )
                  }
                </ProfilePictureLoader>
              </Box>
            </Box>
            <Box
              gridColumn="span 4"
              gridRow="span 4"
              backgroundColor={colors.primary[400]}
              display="flex"
              flexDirection="column" // Așezare pe coloane
              alignItems="center"
              justifyContent="center"
            >
              {/* Rândul pentru butonul Accept */}
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={11}>
                  {/* Utilizăm toată lățimea disponibilă */}
                  <Button
                    variant="contained"
                    size="large" // Mărime mai mare pentru buton
                    fullWidth // Butonul ocupă întreaga lățime disponibilă
                    style={{ backgroundColor: colors.orangeAccent[400] }}
                    onClick={handleAccept}
                  >
                    Acceptă imagine
                  </Button>
                </Grid>
              </Grid>

              {/* Spațiu între butoane */}
              <Box height="20px" />

              {/* Rândul pentru butonul Refuz */}
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={11}>
                  {/* Utilizăm toată lățimea disponibilă */}
                  <Button
                    variant="contained"
                    size="large" // Mărime mai mare pentru buton
                    fullWidth // Butonul ocupă întreaga lățime disponibilă
                    style={{ backgroundColor: colors.redAccent[800] }}
                    onClick={handleReject}
                  >
                    Refuză imagine
                  </Button>
                </Grid>
              </Grid>

              {/* Spațiu între butoane */}
              <Box height="20px" />

              {/* Rândul pentru câmpul de text Motivul refuzului */}
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={11}>
                  {/* Utilizăm toată lățimea disponibilă */}
                  <TextField
                    label="Motivul refuzului"
                    variant="outlined"
                    multiline
                    rows={10}
                    fullWidth // Text field-ul ocupă întreaga lățime disponibilă
                    size="large" // Mărime mai mare pentru text field
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </Grid>
              </Grid>
              {/* Spațiu între butoane */}
              <Box height="20px" />
              {/* Display validation error under text area */}
              {personalInfoData[0]?.user_inputs &&
                personalInfoData[0]?.user_inputs.map((input) => {
                  if (
                    input.field_name === "profile_picture" &&
                    input.validated === false &&
                    input.validation_error
                  ) {
                    return (
                      <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={11}>
                          <Accordion defaultCollapsed>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography
                                color={colors.orangeAccent[500]}
                                variant="h5"
                              >
                                Ultimul motiv de refuz al imaginii de profil
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography key={input.field_name}>
                                {input.validation_error}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Grid>
                    );
                  }
                  return null;
                })}
            </Box>{" "}
            <Box
              gridColumn="span 4"
              gridRow="span 4"
              display="flex"
              alignItems="center"
              justifyContent="center"
            ></Box>
            <Box
              gridColumn="span 4"
              gridRow="span 4"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={11}>
                  <Typography variant="body1" style={{ marginBottom: "10px" }}>
                    Oras de lucru:{" "}
                    {personalInfoData.length > 0
                      ? personalInfoData[0].working_city
                      : ""}
                  </Typography>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={8}>
                      <TextField
                        label="Oras de lucru"
                        variant="outlined"
                        fullWidth
                        value={formData.workingCity}
                        onChange={(e) => handleChange(e, "workingCity")}
                      />
                    </Grid>
                  </Grid>
                  <Box height="20px" /> {/* Spațiu între rânduri */}
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitDataInfo}
                        style={{ height: "100%" }}
                      >
                        Schimbă
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box
              gridColumn="span 4"
              gridRow="span 4"
              backgroundColor={colors.primary[400]}
              display="flex"
              flexDirection="column" // Așezare pe coloane
              alignItems="center"
              justifyContent="center"
            >
              {/* Rândul pentru butonul Accept */}
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={11}>
                  {/* Utilizăm toată lățimea disponibilă */}
                  <Button
                    variant="contained"
                    size="large" // Mărime mai mare pentru buton
                    fullWidth // Butonul ocupă întreaga lățime disponibilă
                    style={{ backgroundColor: colors.orangeAccent[400] }}
                    onClick={handleAcceptPersonalInfo}
                  >
                    Acceptă date personale
                  </Button>
                </Grid>
              </Grid>

              {/* Spațiu între butoane */}
              <Box height="20px" />

              {/* Rândul pentru butonul Refuz */}
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={11}>
                  {/* Utilizăm toată lățimea disponibilă */}
                  <Button
                    variant="contained"
                    size="large" // Mărime mai mare pentru buton
                    fullWidth // Butonul ocupă întreaga lățime disponibilă
                    style={{ backgroundColor: colors.redAccent[800] }}
                    onClick={handleRejectPersonalInfo}
                  >
                    Refuză date personale
                  </Button>
                </Grid>
              </Grid>

              {/* Spațiu între butoane */}
              <Box height="20px" />

              {/* Rândul pentru câmpul de text Motivul refuzului */}
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={11}>
                  {/* Utilizăm toată lățimea disponibilă */}
                  <TextField
                    label="Motivul refuzului datelor personale"
                    variant="outlined"
                    multiline
                    rows={10}
                    fullWidth // Text field-ul ocupă întreaga lățime disponibilă
                    size="large" // Mărime mai mare pentru text field
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </Grid>
              </Grid>
              {/* Spațiu între butoane */}
              <Box height="20px" />
              {/* Display validation error under text area */}
              {personalInfoData[0]?.user_inputs &&
                personalInfoData[0]?.user_inputs.map((input) => {
                  if (
                    input.field_name === "personal_data" &&
                    input.validated === false &&
                    input.validation_error
                  ) {
                    return (
                      <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={11}>
                          <Accordion defaultCollapsed>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography
                                color={colors.orangeAccent[500]}
                                variant="h5"
                              >
                                Ultimul motiv de refuz al datelor personale
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography key={input.field_name}>
                                {input.validation_error}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Grid>
                    );
                  }
                  return null;
                })}
            </Box>{" "}
          </Box>
        </Box>
      )}
    </UserDataProvider>
  );
};

export default PersonalInfo;
