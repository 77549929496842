import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";

const LeasingAgreementFetcher = ({ userId, children }) => {
  const [leasingAgreementData, setLeasingAgreementData] = useState(null);
  const [leasingAgreementError, setError] = useState(null);

  useEffect(() => {
    const fetchLeasingAgreementData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.LEASING_AGREEMENT}?id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if response contains data or error
        if (response.data && response.data.data) {
          setLeasingAgreementData(response.data.data);
          setError(null);
        } else if (response.data && response.data.error) {
          setError(response.data.error.detail);
          setLeasingAgreementData(null);
        }
      } catch (error) {
        console.error("Error fetching leasing agreement data:", error);
        setError("An error occurred while fetching leasing agreement data.");
        setLeasingAgreementData(null);
      }
    };

    fetchLeasingAgreementData();
  }, [userId]);

  return children(leasingAgreementData, leasingAgreementError);
};

export default LeasingAgreementFetcher;
