import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";

const useCheckTokenExpiry = () => {
  const refresh = useRefreshToken();
  const { auth, setAuth } = useAuth();

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.log(err);
        localStorage.removeItem("token");
        localStorage.removeItem("expirationTime");
        localStorage.removeItem("userId");
        setAuth(null);
      }
    };

    const checkTokenExpiry = () => {
      const expirationTime = localStorage.getItem("expirationTime");
      if (expirationTime) {
        const currentTime = Math.floor(Date.now() / 1000);
        const timeRemaining = expirationTime - currentTime;

        // If the token is about to expire in the next 5 minutes (300 seconds)
        const threshold = 300; // 5 minutes in seconds
        if (timeRemaining < threshold) {
          verifyRefreshToken();
        }
      }
    };

    // Check token expiry every minute
    const intervalId = setInterval(checkTokenExpiry, 60 * 1000);

    // Initial check when the component mounts
    checkTokenExpiry();

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [auth, setAuth, refresh]);
};

export default useCheckTokenExpiry;
