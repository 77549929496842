import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import IdCardDataFetcher from "../../context/IdCardDataFetcher";
import UserDataProvider from "../../context/UserDataProvider";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import GoToTextBox from "../../components/GoToTextBox";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import Tooltip from "@mui/material/Tooltip";
import { ROUTES } from "../../helpers/routes";

const WithPfa = () => {
  const theme = useTheme();
  const { userId } = useParams();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const handleGoBack = (userId) => {
    navigate(`${ROUTES.PFA}/${userId}`);
  };

  return (
    <IdCardDataFetcher userId={userId}>
      {(idCardData) => (
        <UserDataProvider userId={userId}>
          {(userData) => (
            <Box m="20px">
              {/* HEADER */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Header
                  title="Sofer cu PFA"
                  subtitle={`Date (sofer cu PFA) pentru user: ${userId}`}
                />
                <Box>
                  <Button
                    sx={{
                      backgroundColor: colors.primary[700],
                      color: colors.orangeAccent[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                    onClick={() => handleGoBack(userId)}
                  >
                    <ArrowBackOutlinedIcon sx={{ mr: "10px" }} />
                    Back
                  </Button>
                </Box>
              </Box>

              <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
              >
                <Box
                  gridColumn="span 4"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <GoToTextBox
                    title="Certificat fiscal"
                    subtitle="Click aici pentru a accesa datele"
                    route={`${ROUTES.FISCAL_CERTIFICATE}/${userId}`}
                    icon={<NoteAddOutlinedIcon fontSize="large" />} // Custom icon
                    statusIcon={
                      userData.length > 0 &&
                      userData[0].user_inputs.some(
                        (input) =>
                          input.field_name === "fiscal_certificate" &&
                          (input.uploaded === true || input.uploaded === null)
                      ) ? (
                        userData[0].user_inputs.some(
                          (input) =>
                            input.field_name === "fiscal_certificate" &&
                            input.validated === true
                        ) ? (
                          <Tooltip
                            title="Documentul a fost încărcat și validat!"
                            arrow
                          >
                            <DoneOutlineOutlinedIcon fontSize="large" />
                          </Tooltip>
                        ) : userData[0].user_inputs.some(
                            (input) =>
                              input.field_name === "fiscal_certificate" &&
                              input.validated === false &&
                              input.validation_error
                          ) ? (
                          <Tooltip title="Documentul a fost refuzat!" arrow>
                            <DangerousOutlinedIcon fontSize="large" />
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title="Documentul a fost încărcat dar nu și validat!"
                            arrow
                          >
                            <UploadFileOutlinedIcon fontSize="large" />
                          </Tooltip>
                        )
                      ) : (
                        <Tooltip
                          title="Documentul nu a fost nici încărcat nici validat!"
                          arrow
                        >
                          <WarningOutlinedIcon fontSize="large" />
                        </Tooltip>
                      )
                    }
                  />
                </Box>
                <Box
                  gridColumn="span 4"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <GoToTextBox
                    title="Constatator"
                    subtitle="Click aici pentru a accesa datele"
                    route={`${ROUTES.PFA_CONFIRMATION_STATEMENT}/${userId}`}
                    icon={<GradingOutlinedIcon fontSize="large" />} // Custom icon
                    statusIcon={
                      userData.length > 0 &&
                      userData[0].user_inputs.some(
                        (input) =>
                          input.field_name === "pfa_confirmation_statement" &&
                          (input.uploaded === true || input.uploaded === null)
                      ) ? (
                        userData[0].user_inputs.some(
                          (input) =>
                            input.field_name === "pfa_confirmation_statement" &&
                            input.validated === true
                        ) ? (
                          <Tooltip
                            title="Documentul a fost încărcat și validat!"
                            arrow
                          >
                            <DoneOutlineOutlinedIcon fontSize="large" />
                          </Tooltip>
                        ) : userData[0].user_inputs.some(
                            (input) =>
                              input.field_name ===
                                "pfa_confirmation_statement" &&
                              input.validated === false &&
                              input.validation_error
                          ) ? (
                          <Tooltip title="Documentul a fost refuzat!" arrow>
                            <DangerousOutlinedIcon fontSize="large" />
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title="Documentul a fost încărcat dar nu și validat!"
                            arrow
                          >
                            <UploadFileOutlinedIcon fontSize="large" />
                          </Tooltip>
                        )
                      ) : (
                        <Tooltip
                          title="Documentul nu a fost nici încărcat nici validat!"
                          arrow
                        >
                          <WarningOutlinedIcon fontSize="large" />
                        </Tooltip>
                      )
                    }
                  />
                </Box>
              </Box>
            </Box>
          )}
        </UserDataProvider>
      )}
    </IdCardDataFetcher>
  );
};

export default WithPfa;
