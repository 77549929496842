import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";

const IdCardDataFetcher = ({ userId, children }) => {
  const [idCardData, setIdCardData] = useState(null);
  const [idCardDataError, setError] = useState(null);
  useEffect(() => {
    const fetchIdCardData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.ID_CARD}?id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.data);
        // Check if response contains data or error
        if (response.data && response.data.data) {
          setIdCardData(response.data.data);
          setError(null);
        } else if (response.data && response.data.error) {
          setError(response.data.error.detail);
          setIdCardData(null);
        }
      } catch (error) {
        console.error("Error fetching id card data:", error);
        setError("An error occurred while fetching id card data.");
        setIdCardData(null);
      }
    };

    fetchIdCardData();
  }, [userId]);

  return children(idCardData, idCardDataError);
};

export default IdCardDataFetcher;
