// routes.js

export const ROUTES = {
  DRIVER_DETAILS: "/driver-details",
  LOGIN: "/login",
  DOCUMENTS: "/documents",
  ID_CARD: "/id-card",
  DRIVER_LICENSE: "/driver-license",
  PERSONAL_INFO: "/personal-info",
  ONBOARDING: "/onboarding",
  CERTIFICATE: "/certificate",
  PFA: "/pfa",
  ARR: "/arr",
  BANKING: "/banking",
  BANKING_STATS: "/banking-stats",
  PFA_ACCOUNT: "/pfa-account",
  DRIVER_ACCOUNT: "/driver-account",
  CRIMINAL_RECORD: "/criminal-record",
  CERTIFICATE_DOCUMENT: "/certificate-document",
  FISCAL_CERTIFICATE: "/fiscal-certificate",
  CAR_DETAILS: "/car-details",
  RCA_INSURANCE: "/rca-insurance",
  TRAVEL_INSURANCE: "/travel-insurance",
  MEDICAL_TESTS: "/medical-tests",
  ARR_WITHOUT_PFA: "/arr-without-pfa",
  PFA_CONFIRMATION_STATEMENT: "/pfa-confirmation-statement",
  DRIVER_WITH_PFA: "/with-pfa",
  DRIVER_WITHOUT_PFA: "/without-pfa",
  INTRA_COMMUNITY_VAT: "/intra-community-vat",
  LEASING_AGREEMENT: "/leasing-agreement",
  CAR_CONTRACT: "/car-contract",
  UBER_BADGE: "/uber-badge",
  BOLT_BADGE: "/bolt-badge",
  CERTIFIED_COPY: "/certified-copy",
  WITH_CERTIFIED_COPY: "/with-certified-copy",
  WITHOUT_CERTIFIED_COPY: "/without-certified-copy",
  CAR_REGISTRATION: "/car-registration",
  PAYMENT_PROOF: "/payment-proof",
  CONNECTED_FLEET_ACCOUNTS: "/connected-fleet-accounts",
  SPV_TVA_FOLDER: "/spv-tva-folder",
  CAR_INFO: "/car-info",
  PAYMENT_PROOF_ARR: "/payment-proof-arr",
  ALTERNATIVE_TRANSPORT_AUTHORIZATION: "/alternative-transport-authorization",
  RENTAL_CONTRACT: "/rental-contract",
  PAYMENT_PROOF_CERTIFIED_COPY: "/payment-proof-certified-copy",
  EXPENSE_TABLE: "/expense-table",
  EXPENSE_NOT_APPROVED_TABLE: "/expense-not-approved-table",
  EXPENSE: "/expense",
};

export const EXTERNAL_ROUTES = {
  USER: "/user",
  SUBSCRIPTION_PAYMENT_INFO: "/subscription_payment_info",
  ID_CARD: "/id_card",
  DRIVER_LICENSE: "/driver_license",
  PROFILE_PICTURE: "/profile_picture",
  CRIMINAL_RECORD: "/criminal_record",
  CERTIFICATE: "/certificate",
  ADMIN_USER: "/admin_user",
  FISCAL_CERTIFICATE: "/fiscal_certificate",
  CAR_IDENTITY_CARD: "/car_identity_card",
  CAR_DETAILS: "/car_details",
  RCA_INSURANCE: "/rca_insurance",
  TRAVEL_INSURANCE: "/travel_insurance",
  MEDICAL_TESTS: "/medical_tests",
  PFA_CONFIRMATION_STATEMENT: "/pfa_confirmation_statement",
  BANK_ACCOUNT_STATEMENT: "/bank_account_statement",
  COMPANY_BANK_ACCOUNT: "/company_account",
  CONNECTED_DRIVER_ACCOUNTS: "/connected_driver_accounts",
  INTRA_COMMUNITY_VAT: "/intra_community_vat",
  LEASING_AGREEMENT: "/leasing_agreement",
  CAR_CONTRACT: "/car_contract",
  CAR_REGISTRATION: "/car_registration",
  PAYMENT_PROOF: "/payment_proof",
  CONNECTED_FLEET_ACCOUNTS: "/connected_fleet_accounts",
  STATISTIC_REPORT: "/statistic/report",
  CERTIFIED_COPY: "/certified_copy",
  FISCAL_REPORT: "/fiscal/report",
  FOLDER: "/folder",
  ALTERNATIVE_TRANSPORT_AUTHORIZATION: "/alternative_transport_authorization",
  BOLT_BADGE: "/bolt_badge",
  UBER_BADGE: "/uber_badge",
  ARR_FOLDER_EMAIL_SEND: "/arr_folder/send",
  EXPENSE: "/expense",
  SINGLE_EXPENSE: "/single_expense",
};
