import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import IdCardDataFetcher from "../../context/IdCardDataFetcher";
import UserDataProvider from "../../context/UserDataProvider";
import GoToTextBox from "../../components/GoToTextBox";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import Tooltip from "@mui/material/Tooltip";
import { ROUTES } from "../../helpers/routes";
import AlternativeTransportAuthorizationFetcher from "../../context/AlternativeTransportAuthorizationFetcher";
import CertifiedCopyDataFetcher from "../../context/CertifiedCopyDataFetcher";

const Arr = () => {
  const theme = useTheme();
  const { userId } = useParams();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const getCarDetailsStatus = (userData) => {
    if (userData.length <= 0 || !userData[0].user_inputs.length) {
      return "inactive";
    }

    const allInputsValidated = [
      "car_registration",
      "travel_insurance",
      "rca_insurance",
    ].every((fieldName) =>
      userData[0].user_inputs.some(
        (input) => input.field_name === fieldName && input.validated
      )
    );

    return allInputsValidated ? "active" : "inactive";
  };

  const getCertifiedCopyStatus = (userData) => {
    if (userData.length <= 0 || !userData[0].user_inputs.length) {
      return "inactive";
    }

    const allInputsValidated = [
      "certified_copy",
      "bolt_badge",
      "uber_badge",
    ].every((fieldName) =>
      userData[0].user_inputs.some(
        (input) => input.field_name === fieldName && input.validated
      )
    );

    return allInputsValidated ? "active" : "inactive";
  };

  const handleGoBack = (userId) => {
    navigate(`${ROUTES.DRIVER_DETAILS}/${userId}`);
  };

  return (
    <IdCardDataFetcher userId={userId}>
      {(idCardData) => (
        <CertifiedCopyDataFetcher userId={userId}>
          {(certifiedCopyData, certifiedCopyError) => (
            <UserDataProvider userId={userId}>
              {(userData) => (
                <AlternativeTransportAuthorizationFetcher userId={userId}>
                  {(alternativeTransportData, alternativeTransportError) => (
                    <Box m="20px">
                      {/* HEADER */}
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Header
                          title="ARR sofer"
                          subtitle={`Date despre ARR pentru user: ${userId}`}
                        />
                        <Box>
                          <Button
                            sx={{
                              backgroundColor: colors.primary[700],
                              color: colors.orangeAccent[100],
                              fontSize: "14px",
                              fontWeight: "bold",
                              padding: "10px 20px",
                            }}
                            onClick={() => handleGoBack(userId)}
                          >
                            <ArrowBackOutlinedIcon sx={{ mr: "10px" }} />
                            Back
                          </Button>
                        </Box>
                      </Box>

                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="140px"
                        gap="20px"
                      >
                        <Box
                          gridColumn="span 4"
                          gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <GoToTextBox
                            title="Date masina"
                            subtitle="Click aici pentru a accesa date masina masinii"
                            route={`${ROUTES.CAR_INFO}/${userId}`}
                            icon={
                              <DirectionsCarFilledOutlinedIcon fontSize="large" />
                            } // Custom icon
                            statusIcon={
                              getCarDetailsStatus(userData) === "active" ? (
                                <Tooltip
                                  title="Capitol date masina finalizat!"
                                  arrow
                                >
                                  <DoneOutlineOutlinedIcon fontSize="large" />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title="Capitolul Date masina nu a fost aprobat integral!"
                                  arrow
                                >
                                  <WarningOutlinedIcon fontSize="large" />
                                </Tooltip>
                              )
                            }
                          />
                        </Box>
                        <Box
                          gridColumn="span 4"
                          gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <GoToTextBox
                            title="Autorizatie ARR - fara autorizatie PFA"
                            subtitle="Click aici pentru a accesa datele"
                            route={`${ROUTES.ARR_WITHOUT_PFA}/${userId}`}
                            icon={<ClearOutlinedIcon fontSize="large" />} // Custom icon
                            statusIcon={
                              userData.length > 0 &&
                              userData[0].user_inputs.some(
                                (input) =>
                                  input.field_name === "arr_payment_proof" &&
                                  (input.uploaded === true ||
                                    input.uploaded === null) // Verifică și pentru valoarea null
                              ) ? (
                                userData[0].user_inputs.some(
                                  (input) =>
                                    input.field_name === "arr_payment_proof" &&
                                    input.validated === true
                                ) ? (
                                  <Tooltip
                                    title="Documentul a fost încărcat și validat!"
                                    arrow
                                  >
                                    <DoneOutlineOutlinedIcon fontSize="large" />
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="Documentul a fost încărcat dar nu și validat!"
                                    arrow
                                  >
                                    <UploadFileOutlinedIcon fontSize="large" />
                                  </Tooltip>
                                )
                              ) : (
                                <Tooltip
                                  title="Documentul nu a fost nici încărcat nici validat!"
                                  arrow
                                >
                                  <WarningOutlinedIcon fontSize="large" />
                                </Tooltip>
                              )
                            }
                            sourceFlow="arrAuthorization"
                            status={
                              alternativeTransportData ? "inactive" : "active"
                            }
                          />
                        </Box>
                        <Box
                          gridColumn="span 4"
                          gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <GoToTextBox
                            title="Autorizatie ARR - cu autorizatie PFA"
                            subtitle="Click aici pentru a accesa datele"
                            route={`${ROUTES.ALTERNATIVE_TRANSPORT_AUTHORIZATION}/${userId}`}
                            icon={<CheckOutlinedIcon fontSize="large" />} // Custom icon
                            statusIcon={
                              userData.length > 0 &&
                              userData[0].user_inputs.some(
                                (input) =>
                                  input.field_name ===
                                    "alternative_transport_authorization" &&
                                  (input.uploaded === true ||
                                    input.uploaded === null) // Verifică și pentru valoarea null
                              ) ? (
                                userData[0].user_inputs.some(
                                  (input) =>
                                    input.field_name ===
                                      "alternative_transport_authorization" &&
                                    input.validated === true
                                ) ? (
                                  <Tooltip
                                    title="Documentul a fost încărcat și validat!"
                                    arrow
                                  >
                                    <DoneOutlineOutlinedIcon fontSize="large" />
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="Documentul a fost încărcat dar nu și validat!"
                                    arrow
                                  >
                                    <UploadFileOutlinedIcon fontSize="large" />
                                  </Tooltip>
                                )
                              ) : (
                                <Tooltip
                                  title="Documentul nu a fost nici încărcat nici validat!"
                                  arrow
                                >
                                  <WarningOutlinedIcon fontSize="large" />
                                </Tooltip>
                              )
                            }
                          />
                        </Box>{" "}
                        <Box
                          gridColumn="span 4"
                          gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <GoToTextBox
                            title="Fara copie conforma"
                            subtitle="Click aici pentru a accesa datele"
                            route={`${ROUTES.WITHOUT_CERTIFIED_COPY}/${userId}`}
                            icon={<CheckOutlinedIcon fontSize="large" />} // Custom icon
                            sourceFlow="certifiedCopy"
                            status={certifiedCopyData ? "inactive" : "active"}
                          />
                        </Box>
                        <Box
                          gridColumn="span 4"
                          gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <GoToTextBox
                            title="Copie conforma"
                            subtitle="Click aici pentru a accesa datele"
                            route={`${ROUTES.WITH_CERTIFIED_COPY}/${userId}`}
                            icon={<CheckOutlinedIcon fontSize="large" />} // Custom icon
                            statusIcon={
                              getCertifiedCopyStatus(userData) === "active" ? (
                                <Tooltip
                                  title="Capitol copie conforma finalizat!"
                                  arrow
                                >
                                  <DoneOutlineOutlinedIcon fontSize="large" />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title="Capitolul copie conforma nu a fost aprobat integral!"
                                  arrow
                                >
                                  <WarningOutlinedIcon fontSize="large" />
                                </Tooltip>
                              )
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </AlternativeTransportAuthorizationFetcher>
              )}
            </UserDataProvider>
          )}
        </CertifiedCopyDataFetcher>
      )}
    </IdCardDataFetcher>
  );
};

export default Arr;
