import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";

const CarRegistrationFetcher = ({ userId, children }) => {
  const [carRegistrationData, setCarRegistrationData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCarRegistrationData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.CAR_REGISTRATION}?id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if response contains data or error
        if (response.data && response.data.data) {
          setCarRegistrationData(response.data.data);
          setError(null);
        } else if (response.data && response.data.error) {
          setError(response.data.error.detail);
          setCarRegistrationData(null);
        }
      } catch (error) {
        console.error("Error fetching car registration data:", error);
        setError("An error occurred while fetching car registration data.");
        setCarRegistrationData(null);
      }
    };

    fetchCarRegistrationData();
  }, [userId]);

  return children(carRegistrationData);
};

export default CarRegistrationFetcher;
