import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";

const CriminalRecordDataFetcher = ({ userId, children }) => {
  const [criminalRecordData, setCriminalRecordData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCriminalRecordData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.CRIMINAL_RECORD}?id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if response contains data or error
        if (response.data && response.data.data) {
          setCriminalRecordData(response.data.data);
          setError(null);
        } else if (response.data && response.data.error) {
          setError(response.data.error.detail);
          setCriminalRecordData(null);
        }
      } catch (error) {
        console.error("Error fetching criminal record data:", error);
        setError("An error occurred while fetching criminal record data.");
        setCriminalRecordData(null);
      }
    };

    fetchCriminalRecordData();
  }, [userId]);

  return children(criminalRecordData);
};

export default CriminalRecordDataFetcher;
