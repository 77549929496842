import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import IdCardDataFetcher from "../../context/IdCardDataFetcher";
import UserDataProvider from "../../context/UserDataProvider";
import { useState } from "react";
import axios from "../../api/axios";
import { EXTERNAL_ROUTES, ROUTES } from "../../helpers/routes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  convertExpiryDate,
  convertToUTCISO,
  formatDateToYYYYMMDD2,
  isValidExpiryDateYYYMMDD,
} from "../../helpers/dateUtils";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ApproveOrRejectInput from "../../context/ApproveOrRejectInput";
import PopupDialog from "../../components/PopupDialog";

const IdCardDetails = () => {
  const theme = useTheme();
  const { userId } = useParams();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [openDataDialog, setOpenDataDialog] = useState(false);
  const [reason, setReason] = useState("");

  const [rotationFront, setRotationFront] = useState(0);
  const [rotationBack, setRotationBack] = useState(0);

  // Handles rotating front image
  const handleRotateFront = () => {
    setRotationFront((prevRotation) => prevRotation + 90);
  };

  // Handles rotating back image
  const handleRotateBack = () => {
    setRotationBack((prevRotation) => prevRotation + 90);
  };

  const [formData, setFormData] = useState({
    lastName: "",
    firstName: "",
    cnp: "",
    county: "",
    city: "",
    expiryDate: "",
    issuedDate: "",
    documentNumber: "",
    address: "",
    issuedBy: "",
    birthCity: "",
    birthCounty: "",
    fatherInitial: "",
  });

  const [error, setError] = useState("");

  const [errors, setErrors] = useState({});

  const handleSubmitDataInfo = (idCardData) => {
    const newErrors = {};
    if (formData.expiryDate !== "") {
      if (!isValidExpiryDateYYYMMDD(formData.expiryDate)) {
        newErrors.expiryDate =
          "Data expirare invalidă (formatul corect: YYYY.MM.DD)";
      }
    }

    setErrors(newErrors);

    // Check if there are any errors
    if (Object.keys(newErrors).length !== 0) {
      // If there are errors, return early without submitting data
      console.log("Validation failed. Cannot submit data.");
      return;
    }

    let intialIdCardDataExpiryDate = idCardData.expiry_date;
    let intialIdCardDataIssuedDate = idCardData.issued_date;
    idCardData.expiry_date = convertToUTCISO(idCardData.expiry_date);
    if (formData.expiryDate !== "") {
      formData.expiryDate = convertExpiryDate(formData.expiryDate);
    }
    idCardData.issued_date = convertToUTCISO(idCardData.issued_date);
    if (formData.issuedDate !== "") {
      formData.issuedDate = convertExpiryDate(formData.issuedDate);
    }

    const updatedIdCardData = {
      ...idCardData,
      last_name:
        formData.lastName !== "" && formData.lastName !== idCardData.last_name
          ? formData.lastName
          : idCardData.last_name,
      first_name:
        formData.firstName !== "" &&
        formData.firstName !== idCardData.first_name
          ? formData.firstName
          : idCardData.first_name,
      cnp:
        formData.cnp !== "" && formData.cnp !== idCardData.cnp
          ? formData.cnp
          : idCardData.cnp,
      county:
        formData.county !== "" && formData.county !== idCardData.county
          ? formData.county
          : idCardData.county,
      city:
        formData.city !== "" && formData.city !== idCardData.city
          ? formData.city
          : idCardData.city,
      expiry_date:
        formData.expiryDate !== "" &&
        formData.expiryDate !== idCardData.expiry_date
          ? formData.expiryDate
          : idCardData.expiry_date,
      issued_date:
        formData.issuedDate !== "" &&
        formData.issuedDate !== idCardData.issued_date
          ? formData.issuedDate
          : idCardData.issued_date,
      address:
        formData.address !== "" && formData.address !== idCardData.address
          ? formData.address
          : idCardData.address,
      document_number:
        formData.documentNumber !== "" &&
        formData.documentNumber !== idCardData.document_number
          ? formData.documentNumber
          : idCardData.document_number,
      issued_by:
        formData.issuedBy !== "" && formData.issuedBy !== idCardData.issued_by
          ? formData.issuedBy
          : idCardData.issued_by,
      birth_city:
        formData.birthCity !== "" &&
        formData.birthCity !== idCardData.birth_city
          ? formData.birthCity
          : idCardData.birth_city,
      birth_county:
        formData.birthCounty !== "" &&
        formData.birthCounty !== idCardData.birth_county
          ? formData.birthCounty
          : idCardData.birth_county,
      father_initial:
        formData.fatherInitial !== "" &&
        formData.fatherInitial !== idCardData.father_initial
          ? formData.fatherInitial
          : idCardData.father_initial,
    };
    idCardData.expiry_date = intialIdCardDataExpiryDate;
    idCardData.issued_date = intialIdCardDataIssuedDate;
    console.log("Updated idCardData:", updatedIdCardData);

    // Reset form data after submission
    setFormData({
      lastName: "",
      firstName: "",
      cnp: "",
      county: "",
      city: "",
      expiryDate: "",
      issuedDate: "",
      address: "",
      documentNumber: "",
      birthCity: "",
      birthCounty: "",
      issuedBy: "",
      fatherInitial: "",
    });

    // Send the updated data to the server
    sendDataToServer(updatedIdCardData);
    // Reload the page after data is submitted
  };

  const sendDataToServer = (updatedData) => {
    // Get token from local storage
    const token = localStorage.getItem("token");

    // Define the data to be sent to the server
    const postData = {
      ...updatedData,
    };

    // Set the authorization header with the token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Adăugăm token-ul în antetul de autorizare
      },
    };

    // Send the updated data to the server
    axios
      .post(`${EXTERNAL_ROUTES.ID_CARD}/${userId}`, postData, config)
      .then((response) => {
        console.log("Data successfully sent:", response.data);
        setOpenDataDialog(true);
      })
      .catch((error) => {
        setError(error?.response?.data?.error?.detail);
      });
  };

  const handleChange = (e, field) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const handleAccept = async () => {
    // Call the approveInput method from ApproveOrRejectInput component
    try {
      const response = await ApproveOrRejectInput({ userId }).approveInput(
        "id_card"
      );
      console.log("Input approved:", response);

      setOpenApproveDialog(true);
    } catch (error) {
      console.error("Error approving input:", error);
      alert("Error: " + error);
    }
  };

  const handleReject = async () => {
    // Call the rejectInputWithReason method from ApproveOrRejectInput component
    if (reason.trim() !== "") {
      try {
        const response = await ApproveOrRejectInput({
          userId,
        }).rejectInputWithReason("id_card", reason);
        console.log("Input rejected with reason:", response);

        setOpenRejectDialog(true);
      } catch (error) {
        console.error("Error rejecting input:", error);
        alert("Error: " + error);
      }
    } else {
      alert("Te rog să introduci un motiv pentru refuz.");
    }
  };

  const handleGoBack = (userId) => {
    navigate(`${ROUTES.ONBOARDING}/${userId}`);
  };

  const handleCloseDialog = () => {
    // Închide dialogul de succes
    setOpenApproveDialog(false);
    window.location.reload();
  };

  const handleCloseDialogReject = () => {
    // Închide dialogul de succes
    setOpenRejectDialog(false);
    window.location.reload();
  };

  const handleCloseDataDialog = () => {
    // Închide dialogul de succes
    setOpenDataDialog(false);
    window.location.reload();
  };

  return (
    <IdCardDataFetcher userId={userId}>
      {(idCardData, idCardDataError) => (
        <UserDataProvider userId={userId} setError={setError}>
          {(userData) => (
            <Box m="20px">
              <PopupDialog
                header="Date actualizate!"
                content="Datele au fost actualizate cu succes!"
                open={openDataDialog}
                onClose={handleCloseDataDialog}
              ></PopupDialog>
              <PopupDialog
                header="Document aprobat!"
                content="Documentul a fost aprobat cu succes!"
                open={openApproveDialog}
                onClose={handleCloseDialog}
              ></PopupDialog>
              <PopupDialog
                header="Document refuzat!"
                content="Documentul a fost refuzat!"
                open={openRejectDialog}
                onClose={handleCloseDialogReject}
              ></PopupDialog>
              {/* HEADER */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Header
                  title="Carte de identitate sofer"
                  subtitle={`Detaliile cartii de identitate pentru userul: ${userId}`}
                />
                <Box>
                  <Button
                    sx={{
                      backgroundColor: colors.primary[700],
                      color: colors.orangeAccent[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                    onClick={() => handleGoBack(userId)}
                  >
                    <ArrowBackOutlinedIcon sx={{ mr: "10px" }} />
                    Back
                  </Button>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {/* Display error message */}
                {error && (
                  <Alert severity="error">Error message: {error}</Alert>
                )}{" "}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {idCardDataError && (
                  <Alert severity="error">
                    Error message: {idCardDataError}
                  </Alert>
                )}{" "}
              </Box>{" "}
              <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
              >
                {" "}
                <Box
                  gridColumn="span 4"
                  gridRow="span 1"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleRotateFront}
                    >
                      Roteste imaginea fata
                    </Button>
                  </Box>{" "}
                </Box>{" "}
                <Box
                  gridColumn="span 4"
                  gridRow="span 1"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleRotateBack}
                    >
                      Roteste imaginea spate
                    </Button>
                  </Box>{" "}
                </Box>{" "}
                <Box
                  gridColumn="span 4"
                  gridRow="span 1"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                ></Box>{" "}
                <Box
                  gridColumn="span 4"
                  gridRow="span 5"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {idCardData && idCardData.front_file ? (
                    <img
                      src={idCardData.front_file}
                      alt="Buletin"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        transform: `rotate(${rotationFront}deg)`,
                      }}
                    />
                  ) : (
                    <Typography
                      variant="h5"
                      color={colors.orangeAccent[500]}
                      sx={{ mt: "15px" }}
                    >
                      No picture
                    </Typography>
                  )}{" "}
                </Box>{" "}
                <Box
                  gridColumn="span 4"
                  gridRow="span 5"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {idCardData && idCardData.back_file ? (
                    <img
                      src={idCardData.back_file}
                      alt="Buletin - spate"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        transform: `rotate(${rotationBack}deg)`,
                      }}
                    />
                  ) : (
                    <Typography
                      variant="h5"
                      color={colors.orangeAccent[500]}
                      sx={{ mt: "15px" }}
                    >
                      No picture
                    </Typography>
                  )}
                </Box>
                <Box
                  gridColumn="span 4"
                  gridRow="span 10"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={11}>
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "10px" }}
                      >
                        Nume: {idCardData?.last_name || ""}
                      </Typography>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={8}>
                          <TextField
                            label="Nume"
                            variant="outlined"
                            fullWidth
                            value={formData.lastName}
                            onChange={(e) => handleChange(e, "lastName")}
                          />
                        </Grid>
                      </Grid>
                      <Box height="20px" /> {/* Spațiu între rânduri */}
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "10px" }}
                      >
                        Prenume: {idCardData?.first_name || ""}
                      </Typography>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={8}>
                          <TextField
                            label="Prenume"
                            variant="outlined"
                            fullWidth
                            value={formData.firstName}
                            onChange={(e) => handleChange(e, "firstName")}
                            // Aici adaugați valoarea și funcția de actualizare pentru prenume
                          />
                        </Grid>
                      </Grid>
                      <Box height="20px" /> {/* Spațiu între rânduri */}
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "10px" }}
                      >
                        CNP: {idCardData?.cnp || ""}
                      </Typography>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={8}>
                          <TextField
                            label="CNP"
                            variant="outlined"
                            fullWidth
                            value={formData.cnp}
                            onChange={(e) => handleChange(e, "cnp")}
                            // Aici adaugați valoarea și funcția de actualizare pentru CNP
                          />
                        </Grid>
                      </Grid>
                      <Box height="20px" /> {/* Spațiu între rânduri */}
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "10px" }}
                      >
                        Judet/Sector: {idCardData?.county || ""}
                      </Typography>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={8}>
                          <TextField
                            label="Judet"
                            variant="outlined"
                            fullWidth
                            value={formData.county}
                            onChange={(e) => handleChange(e, "county")}
                            // Aici adaugați valoarea și funcția de actualizare pentru CNP
                          />
                        </Grid>
                      </Grid>
                      <Box height="20px" /> {/* Spațiu între rânduri */}
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "10px" }}
                      >
                        Oras: {idCardData?.city || ""}
                      </Typography>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={8}>
                          <TextField
                            label="Oras"
                            variant="outlined"
                            fullWidth
                            value={formData.city}
                            onChange={(e) => handleChange(e, "city")}
                            // Aici adaugați valoarea și funcția de actualizare pentru CNP
                          />
                        </Grid>
                      </Grid>
                      <Box height="20px" /> {/* Spațiu între rânduri */}
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "10px" }}
                      >
                        Adresa: {idCardData?.address || ""}
                      </Typography>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={8}>
                          <TextField
                            label="Adresa"
                            variant="outlined"
                            fullWidth
                            value={formData.address}
                            onChange={(e) => handleChange(e, "address")}
                            // Aici adaugați valoarea și funcția de actualizare pentru CNP
                          />
                        </Grid>
                      </Grid>
                      <Box height="20px" /> {/* Spațiu între rânduri */}
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "10px" }}
                      >
                        Emis de: {idCardData?.issued_by || ""}
                      </Typography>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={8}>
                          <TextField
                            label="Emis de"
                            variant="outlined"
                            fullWidth
                            value={formData.issuedBy}
                            onChange={(e) => handleChange(e, "issuedBy")}
                          />
                        </Grid>
                      </Grid>
                      <Box height="20px" /> {/* Spațiu între rânduri */}
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "10px" }}
                      >
                        Oras nastere: {idCardData?.birth_city || ""}
                      </Typography>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={8}>
                          <TextField
                            label="Oras nastere"
                            variant="outlined"
                            fullWidth
                            value={formData.birthCity}
                            onChange={(e) => handleChange(e, "birthCity")}
                          />
                        </Grid>
                      </Grid>
                      <Box height="20px" /> {/* Spațiu între rânduri */}
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "10px" }}
                      >
                        Judet nastere: {idCardData?.birth_county || ""}
                      </Typography>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={8}>
                          <TextField
                            label="Judet nastere"
                            variant="outlined"
                            fullWidth
                            value={formData.birthCounty}
                            onChange={(e) => handleChange(e, "birthCounty")}
                          />
                        </Grid>
                      </Grid>
                      <Box height="20px" /> {/* Spațiu între rânduri */}
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "10px" }}
                      >
                        Initiala tata: {idCardData?.father_initial || ""}
                      </Typography>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={8}>
                          <TextField
                            label="Initiala tata"
                            variant="outlined"
                            fullWidth
                            value={formData.fatherInitial}
                            onChange={(e) => handleChange(e, "fatherInitial")}
                          />
                        </Grid>
                      </Grid>
                      <Box height="20px" /> {/* Spațiu între rânduri */}
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "10px" }}
                      >
                        Data emitere:{" "}
                        {idCardData?.issued_date
                          ? formatDateToYYYYMMDD2(idCardData.issued_date)
                          : ""}
                      </Typography>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={8}>
                          <TextField
                            label="Data emitere"
                            variant="outlined"
                            fullWidth
                            value={formData.issuedDate}
                            onChange={(e) => handleChange(e, "issuedDate")}
                            error={!!errors.issuedDate} // Setează eroarea pe baza existenței erorii
                            helperText={errors.issuedDate} // Afișează textul erorii
                          />
                        </Grid>
                      </Grid>
                      <Box height="20px" /> {/* Spațiu între rânduri */}
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "10px" }}
                      >
                        Data expirare:{" "}
                        {idCardData?.expiry_date
                          ? formatDateToYYYYMMDD2(idCardData.expiry_date)
                          : ""}
                      </Typography>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={8}>
                          <TextField
                            label="Data expirare"
                            variant="outlined"
                            fullWidth
                            value={formData.expiryDate}
                            onChange={(e) => handleChange(e, "expiryDate")}
                            error={!!errors.expiryDate} // Setează eroarea pe baza existenței erorii
                            helperText={errors.expiryDate} // Afișează textul erorii
                          />
                        </Grid>
                      </Grid>
                      <Box height="20px" /> {/* Spațiu între rânduri */}
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "10px" }}
                      >
                        Nr document: {idCardData?.document_number || ""}
                      </Typography>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={8}>
                          <TextField
                            label="Nr document"
                            variant="outlined"
                            fullWidth
                            value={formData.documentNumber}
                            onChange={(e) => handleChange(e, "documentNumber")}
                            // Aici adaugați valoarea și funcția de actualizare pentru CNP
                          />
                        </Grid>
                      </Grid>
                      <Box height="20px" /> {/* Spațiu între rânduri */}
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmitDataInfo(idCardData)} // Pass idCardData to handleSubmitDataInfo
                            style={{ height: "100%" }} // Înălțimea butonului este setată pe 100% pentru a se potrivi cu înălțimea câmpului de introducere
                          >
                            Schimbă
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  gridColumn="span 4"
                  gridRow="span 4"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  flexDirection="column" // Așezare pe coloane
                  alignItems="center"
                  justifyContent="center"
                >
                  {/* Rândul pentru butonul Accept */}
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={11}>
                      {/* Utilizăm toată lățimea disponibilă */}
                      <Button
                        variant="contained"
                        size="large" // Mărime mai mare pentru buton
                        fullWidth // Butonul ocupă întreaga lățime disponibilă
                        style={{ backgroundColor: colors.orangeAccent[400] }}
                        onClick={handleAccept}
                      >
                        Acceptă
                      </Button>
                    </Grid>
                  </Grid>

                  {/* Spațiu între butoane */}
                  <Box height="20px" />

                  {/* Rândul pentru butonul Refuz */}
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={11}>
                      {/* Utilizăm toată lățimea disponibilă */}
                      <Button
                        variant="contained"
                        size="large" // Mărime mai mare pentru buton
                        fullWidth // Butonul ocupă întreaga lățime disponibilă
                        style={{ backgroundColor: colors.redAccent[800] }}
                        onClick={handleReject}
                      >
                        Refuză
                      </Button>
                    </Grid>
                  </Grid>

                  {/* Spațiu între butoane */}
                  <Box height="20px" />

                  {/* Rândul pentru câmpul de text Motivul refuzului */}
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={11}>
                      {/* Utilizăm toată lățimea disponibilă */}
                      <TextField
                        label="Motivul refuzului"
                        variant="outlined"
                        multiline
                        rows={10}
                        fullWidth // Text field-ul ocupă întreaga lățime disponibilă
                        size="large" // Mărime mai mare pentru text field
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  {/* Spațiu între butoane */}
                  <Box height="20px" />
                  {/* Display validation error under text area */}
                  {userData[0]?.user_inputs &&
                    userData[0]?.user_inputs.map((input) => {
                      if (
                        input.field_name === "id_card" &&
                        input.validated === false &&
                        input.validation_error
                      ) {
                        return (
                          <Grid container justifyContent="center" spacing={2}>
                            <Grid item xs={11}>
                              <Accordion defaultCollapsed>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <Typography
                                    color={colors.orangeAccent[500]}
                                    variant="h5"
                                  >
                                    Ultimul motiv de refuz
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography key={input.field_name}>
                                    {input.validation_error}
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          </Grid>
                        );
                      }
                      return null;
                    })}
                </Box>
              </Box>
            </Box>
          )}
        </UserDataProvider>
      )}
    </IdCardDataFetcher>
  );
};

export default IdCardDetails;
