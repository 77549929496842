// Accounting DTO
class AccountingDTO {
  constructor(data) {
    this.time_period_start = data?.time_period_start || null;
    this.time_period_end = data?.time_period_end || null;
    this.gross_earnings = data?.gross_earnings || 0;
    this.expenses = new ExpensesDTO(data?.expenses);
    this.taxes = new TaxesDTO(data?.taxes);
    this.net_amount = data?.net_amount || 0;
    this.cas_info = new CasInfoDTO(data?.cas_info);
    this.total = new TotalDTO(data);
  }
}

// DTO for expenses
class ExpensesDTO {
  constructor(data) {
    this.administrative = data?.administrative || 0;
    this.fare = data?.fare || 0;
  }
}

// DTO for taxes
class TaxesDTO {
  constructor(data) {
    this.income_tax = data?.income_tax || 0;
    this.cas = data?.cas || 0;
    this.cass = data?.cass || 0;
    this.intra_community_tva = data?.intra_community_tva || 0;
  }
}

// DTO for CAS info
class CasInfoDTO {
  constructor(data) {
    this.first_limit = data?.first_limit || 0;
    this.first_limit_amount = data?.first_limit_amount || 0;
    this.second_limit = data?.second_limit || 0;
    this.second_limit_amount = data?.second_limit_amount || 0;
  }
}

class TotalDTO {
  constructor(data) {
    this.taxes =
      (data?.taxes?.income_tax || 0) +
      (data?.taxes?.cas || 0) +
      (data?.taxes?.cass || 0) +
      (data?.taxes?.intra_community_tva || 0);
    this.expenses =
      (data?.expenses?.administrative || 0) + (data?.expenses?.fare || 0);
  }
}

export default AccountingDTO;
