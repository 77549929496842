import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";
const IntraCommunityVatFetcher = ({ userId, children }) => {
  const [intraCommunityVatData, setIntraCommunityVatData] = useState(null);
  const [intraCommunityVatError, setError] = useState(null);
  useEffect(() => {
    const fetchIntraCommunityVatData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.INTRA_COMMUNITY_VAT}?id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if response contains data or error
        if (response.data && response.data.data) {
          setIntraCommunityVatData(response.data.data);
          setError(null);
        } else if (response.data && response.data.error) {
          setError(response.data.error.detail);
          setIntraCommunityVatData(null);
        }
      } catch (error) {
        console.error("Error fetching intra community data:", error);
        setError("An error occurred while fetching intra community data.");
        setIntraCommunityVatData(null);
      }
    };

    fetchIntraCommunityVatData();
  }, [userId]);

  return children(intraCommunityVatData, intraCommunityVatError);
};

export default IntraCommunityVatFetcher;
