import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";

const CarDetailsFetcher = ({ userId, children }) => {
  const [carDetailsData, setCarDetailsData] = useState(null);
  const [carDetailsError, setError] = useState(null);

  useEffect(() => {
    const fetchCarDetailsData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.CAR_DETAILS}?id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if response contains data or error
        if (response.data && response.data.data) {
          setCarDetailsData(response.data.data);
          setError(null);
        } else if (response.data && response.data.error) {
          setError(response.data.error.detail);
          setCarDetailsData(null);
        }
      } catch (error) {
        console.error("Error fetching car details data:", error);
        setError("An error occurred while fetching car details data.");
        setCarDetailsData(null);
      }
    };

    fetchCarDetailsData();
  }, [userId]);

  return children(carDetailsData, carDetailsError);
};

export default CarDetailsFetcher;
