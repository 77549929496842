import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";

const DownloadSpvCuiFolder = ({ userId, children }) => {
  const [pfaCuiZip, setSpvCuiZip] = useState(null);
  const [spvCuiError, setError] = useState(null);
  const [isSpvCuiLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.FOLDER}?id=${userId}&scope=spv_cui`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          }
        );

        // Check if response contains data or error
        if (response.data) {
          setSpvCuiZip(response.data);
          setError(null);
        } else if (response.error) {
          console.log(response.error);
          setError(response.error.detail);
          setSpvCuiZip(null);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching zip spv_cui data:", error);
        setError("An error occurred while downloading zip data.");
        setSpvCuiZip(null);
        setIsLoading(false);
      }
    };

    fetchFile();
  }, [userId]);

  return children(pfaCuiZip, spvCuiError, isSpvCuiLoading);
};

export default DownloadSpvCuiFolder;
