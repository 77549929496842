const checkStatus = (userData, requiredFields) => {
  if (
    !Array.isArray(userData) ||
    userData.length <= 0 ||
    !userData[0].user_inputs?.length
  ) {
    return "inactive";
  }

  const allInputsValidated = requiredFields.every((fieldName) =>
    userData[0].user_inputs.some(
      (input) => input.field_name === fieldName && input.validated
    )
  );

  return allInputsValidated ? "active" : "inactive";
};

// Export specific status functions using the general-purpose function

export const getChapter = (userData) => {
  if (getOnboardingStatus(userData) === "inactive") return "onboarding";
  if (getAtestatChapterStatus(userData) === "inactive") return "atestat";

  if (getPfaChapterStatus(userData) === "inactive") return "pfa";

  if (getArrChapterStatus(userData) === "inactive") return "arr";

  if (getBankingChapterStatus(userData) === "inactive") return "banking";

  return "finalizat";
};

export const getOnboardingStatus = (userData) =>
  checkStatus(userData, [
    "profile_picture",
    "driver_license",
    "personal_data",
    "id_card",
  ]);

export const getAtestatChapterStatus = (userData) =>
  checkStatus(userData, [
    "profile_picture",
    "driver_license",
    "personal_data",
    "id_card",
    "certificate",
  ]);

export const getPfaChapterStatus = (userData) =>
  checkStatus(userData, [
    "profile_picture",
    "driver_license",
    "personal_data",
    "id_card",
    "certificate",
    "fiscal_certificate",
    "pfa_confirmation_statement",
  ]);

export const getArrChapterStatus = (userData) =>
  checkStatus(userData, [
    "profile_picture",
    "driver_license",
    "personal_data",
    "id_card",
    "certificate",
    "fiscal_certificate",
    "pfa_confirmation_statement",
    "car_registration",
    "travel_insurance",
    "alternative_transport_authorization",
    "certified_copy",
    "bolt_badge",
    "uber_badge",
  ]);

export const getBankingChapterStatus = (userData) =>
  checkStatus(userData, [
    "profile_picture",
    "driver_license",
    "personal_data",
    "id_card",
    "certificate",
    "fiscal_certificate",
    "pfa_confirmation_statement",
    "car_registration",
    "travel_insurance",
    "arr_payment_proof",
    "alternative_transport_authorization",
    "certified_copy",
    "bolt_badge",
    "uber_badge",
    "bank_account_statement",
    "intra_community_vat",
  ]);

export const hasWaitingExpenses = (userData) => {
  if (
    !Array.isArray(userData) ||
    userData.length <= 0 ||
    !userData[0].user_inputs?.length
  ) {
    return false;
  }

  const hasExpense = userData[0].user_inputs.some((input) =>
    input.field_name.startsWith("expense_")
  );

  return hasExpense;
};

export const getExpensesStatus = (userData) =>
  hasWaitingExpenses(userData) ? "active" : "inactive";

export const getCertificateStatus = (userData) =>
  checkStatus(userData, ["certificate"]);

export const getBillsStatus = () => "active"; // Placeholder for future implementation

export const getPfaStatus = (userData) =>
  checkStatus(userData, ["pfa_confirmation_statement", "fiscal_certificate"]);

export const getBankingStatus = (userData) =>
  checkStatus(userData, ["bank_account_statement", "intra_community_vat"]);

export const getUberAndBoltStatus = (userData) => true;

export const hasDocumentsToBeVerified = (userData) => {
  if (!userData || userData.length === 0) {
    return false; // No user data available
  }

  const userInputs = userData.user_inputs;

  if (!userInputs || userInputs.length === 0) {
    return false; // No user inputs available
  }

  const hasUnverifiedDocuments = userInputs.some(
    (input) =>
      input.uploaded === true &&
      (input.validated === false ||
        input.validated === null ||
        input.validated === undefined)
  );

  return hasUnverifiedDocuments; // Return true if there are uploaded but not validated documents
};
