import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../theme";

const PopupDialog = ({ header, content, open, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        style={{
          backgroundColor: colors.primary[700],
          color: colors.orangeAccent[100],
        }}
      >
        {header}
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: colors.primary[700],
          color: colors.orangeAccent[100],
        }}
      >
        <Typography>{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            color: colors.orangeAccent[100],
            backgroundColor: colors.primary[700],
          }}
          onClick={onClose}
          color="primary"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupDialog;
