import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";

const DownloadPfaFolder = ({ userId, children }) => {
  const [pfaZip, setPfaZip] = useState(null);
  const [errorZip, setError] = useState(null);
  const [isPfaLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.FOLDER}?id=${userId}&scope=pfa`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          }
        );

        // Check if response contains data or error
        if (response.data) {
          setPfaZip(response.data);
          setError(null);
        } else if (response.error) {
          console.log(response.error);
          setError(response.error.detail);
          setPfaZip(null);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching zip data:", error);
        setError("An error occurred while downloading zip data.");
        setPfaZip(null);
        setIsLoading(false);
      }
    };

    fetchFile();
  }, [userId]);

  return children(pfaZip, errorZip, isPfaLoading);
};

export default DownloadPfaFolder;
