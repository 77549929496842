import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import { Routes, Route } from "react-router-dom";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Sidebar from "./scenes/global/Sidebar";
import Topbar from "./scenes/global/Topbar";
import Bar from "./scenes/bar";
import Team from "./scenes/team";
import FAQ from "./scenes/faq";
import Invoices from "./scenes/invoices/invoices";
import Drivers from "./scenes/drivers/drivers";
import Missing from "./components/Missing";
import useAuth from "./hooks/useAuth";
import Pie from "./scenes/pie";
import Line from "./components/LineChart";
import Geography from "./scenes/geography";
import LoginForm from "./scenes/LoginForm/LoginForm";
import Dashboard from "./scenes/Dashboard";
import DriverDetailsTest from "./components/DriverDetailsTest";
import DriverDetails from "./scenes/driverDetails/driverDetails";
import IdCardDetails from "./scenes/onboarding/IdCardDetails";
import Documents from "./scenes/documents";
import { ROUTES } from "./helpers/routes";
import Onboarding from "./scenes/onboarding";
import DriverLicense from "./scenes/onboarding/DriverLicense";
import PersonalInfo from "./scenes/personalInfo";
import Certificate from "./scenes/certificate";
import Pfa from "./scenes/pfa";
import CertificateDocument from "./scenes/certificate/certificateDocument";
import CriminalRecord from "./scenes/certificate/criminalRecord";
import Arr from "./scenes/arr";
import Banking from "./scenes/banking";
import PfaAccount from "./scenes/pfaAccount";
import DriverAccount from "./scenes/driverAccount";
import FiscalCertificate from "./scenes/pfa/fiscalCertificate";
import CarDetails from "./scenes/arr/carDetails";
import RcaInsurance from "./scenes/arr/rcaInsurance";
import TravelInsurance from "./scenes/arr/travelInsurance";
import ArrWithoutPfa from "./scenes/arr/withoutPfa";
import MedicalTests from "./scenes/arr/medicalTests";
import WithPfa from "./scenes/pfa/withPfa";
import ConfirmationStatement from "./scenes/pfa/confirmationStatement";
import IntraCommunityVat from "./scenes/pfaAccount/intraCommunityVat";
import ConnectedFleetAccounts from "./scenes/pfaAccount/connectedFleetAccounts";
import WithoutPfa from "./scenes/pfa/noPfa";
import BankingStats from "./scenes/banking/bankingStats";
import SpvTvaFolder from "./scenes/banking/spvTva";
import CarInfo from "./scenes/arr/carInfo";
import PaymentProofArr from "./scenes/arr/paymentProof";
import AlternativeTransportAuthorization from "./scenes/arr/alternativeTransportAuthorization";
import WithCertifiedCopy from "./scenes/arr/withCertifiedCopy";
import CertifiedCopy from "./scenes/arr/certifiedCopy";
import BoltBadge from "./scenes/arr/boltBadge";
import UberBadge from "./scenes/arr/uberBadge";
import WithoutCertifiedCopy from "./scenes/arr/withoutCertifiedCopy";
import ExpenseTable from "./scenes/expense/table";
import NotApprovedExpenses from "./scenes/expense/notApproved";
import Expense from "./scenes/expense/expense";
import LeasingAgreement from "./scenes/arr/leasingAgreement";
import CarIdentity from "./scenes/arr/carIdentity";
import PaymentProofCertifiedCopy from "./scenes/arr/paymentProofCertifiedCopy";
import CarContract from "./scenes/arr/carContract";

function App() {
  const [theme, colorMode] = useMode();
  const { auth } = useAuth();
  const isLogged = auth?.isLogged;
  const hasLocalToken = localStorage.getItem("token");

  const isAuthorized = isLogged || hasLocalToken;

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <PersistLogin />
          {isAuthorized && <Sidebar />}
          <div
            className="content"
            style={{ marginLeft: isAuthorized ? "270px" : 0 }}
          >
            {isAuthorized && <Topbar />}
            <Routes>
              <Route path="/login" element={<LoginForm />} />
              <Route element={<RequireAuth />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/team" element={<Team />} />
                <Route path="/drivers" element={<Drivers />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/line" element={<Line />} />
                <Route path="/geography" element={<Geography />} />
                <Route
                  path={ROUTES.DRIVER_DETAILS + "/:userId"}
                  element={<DriverDetails />}
                />
                <Route
                  path={ROUTES.DOCUMENTS + "/:userId"}
                  element={<Documents />}
                />
                <Route
                  path={ROUTES.ONBOARDING + "/:userId"}
                  element={<Onboarding />}
                />
                <Route
                  path={ROUTES.ID_CARD + "/:userId"}
                  element={<IdCardDetails />}
                />
                <Route
                  path={ROUTES.DRIVER_LICENSE + "/:userId"}
                  element={<DriverLicense />}
                />
                <Route
                  path="/driver-details-test/:userId"
                  element={<DriverDetailsTest />}
                />
                <Route
                  path={ROUTES.PERSONAL_INFO + "/:userId"}
                  element={<PersonalInfo />}
                />
                <Route
                  path={ROUTES.CERTIFICATE + "/:userId"}
                  element={<Certificate />}
                />
                <Route
                  path={ROUTES.CERTIFICATE_DOCUMENT + "/:userId"}
                  element={<CertificateDocument />}
                />
                <Route
                  path={ROUTES.CRIMINAL_RECORD + "/:userId"}
                  element={<CriminalRecord />}
                />
                <Route path={ROUTES.PFA + "/:userId"} element={<Pfa />} />
                <Route path={ROUTES.ARR + "/:userId"} element={<Arr />} />
                <Route
                  path={ROUTES.BANKING + "/:userId"}
                  element={<Banking />}
                />
                <Route
                  path={ROUTES.PFA_ACCOUNT + "/:userId"}
                  element={<PfaAccount />}
                />
                <Route
                  path={ROUTES.DRIVER_ACCOUNT + "/:userId"}
                  element={<DriverAccount />}
                />
                <Route
                  path={ROUTES.FISCAL_CERTIFICATE + "/:userId"}
                  element={<FiscalCertificate />}
                />
                <Route
                  path={ROUTES.CAR_DETAILS + "/:userId"}
                  element={<CarDetails />}
                />
                <Route
                  path={ROUTES.CAR_CONTRACT + "/:userId"}
                  element={<CarContract />}
                />
                <Route
                  path={ROUTES.CAR_REGISTRATION + "/:userId"}
                  element={<CarIdentity />}
                />
                <Route
                  path={ROUTES.RCA_INSURANCE + "/:userId"}
                  element={<RcaInsurance />}
                />
                <Route
                  path={ROUTES.TRAVEL_INSURANCE + "/:userId"}
                  element={<TravelInsurance />}
                />
                <Route
                  path={ROUTES.ARR_WITHOUT_PFA + "/:userId"}
                  element={<ArrWithoutPfa />}
                />
                <Route
                  path={ROUTES.MEDICAL_TESTS + "/:userId"}
                  element={<MedicalTests />}
                />
                <Route
                  path={ROUTES.DRIVER_WITH_PFA + "/:userId"}
                  element={<WithPfa />}
                />
                <Route
                  path={ROUTES.DRIVER_WITHOUT_PFA + "/:userId"}
                  element={<WithoutPfa />}
                />
                <Route
                  path={ROUTES.PFA_CONFIRMATION_STATEMENT + "/:userId"}
                  element={<ConfirmationStatement />}
                />
                <Route
                  path={ROUTES.INTRA_COMMUNITY_VAT + "/:userId"}
                  element={<IntraCommunityVat />}
                />
                <Route
                  path={ROUTES.BANKING_STATS + "/:userId"}
                  element={<BankingStats />}
                />
                <Route
                  path={ROUTES.SPV_TVA_FOLDER + "/:userId"}
                  element={<SpvTvaFolder />}
                />
                <Route
                  path={ROUTES.CAR_INFO + "/:userId"}
                  element={<CarInfo />}
                />{" "}
                <Route
                  path={ROUTES.PAYMENT_PROOF_ARR + "/:userId"}
                  element={<PaymentProofArr />}
                />
                <Route
                  path={ROUTES.PAYMENT_PROOF_CERTIFIED_COPY + "/:userId"}
                  element={<PaymentProofCertifiedCopy />}
                />
                <Route
                  path={ROUTES.WITH_CERTIFIED_COPY + "/:userId"}
                  element={<WithCertifiedCopy />}
                />
                <Route
                  path={ROUTES.WITHOUT_CERTIFIED_COPY + "/:userId"}
                  element={<WithoutCertifiedCopy />}
                />
                <Route
                  path={ROUTES.CERTIFIED_COPY + "/:userId"}
                  element={<CertifiedCopy />}
                />
                <Route
                  path={ROUTES.ALTERNATIVE_TRANSPORT_AUTHORIZATION + "/:userId"}
                  element={<AlternativeTransportAuthorization />}
                />
                <Route
                  path={ROUTES.BOLT_BADGE + "/:userId"}
                  element={<BoltBadge />}
                />
                <Route
                  path={ROUTES.UBER_BADGE + "/:userId"}
                  element={<UberBadge />}
                />
                <Route
                  path={ROUTES.EXPENSE_TABLE + "/:userId"}
                  element={<ExpenseTable />}
                />
                <Route
                  path={ROUTES.EXPENSE + "/:expenseId"}
                  element={<Expense />}
                />
                <Route
                  path={ROUTES.EXPENSE_NOT_APPROVED_TABLE + "/:userId"}
                  element={<NotApprovedExpenses />}
                />
                <Route
                  path={ROUTES.CONNECTED_FLEET_ACCOUNTS + "/:userId"}
                  element={<ConnectedFleetAccounts />}
                />
                <Route
                  path={ROUTES.LEASING_AGREEMENT + "/:userId"}
                  element={<LeasingAgreement />}
                />
              </Route>
              <Route path="*" element={<Missing />} />
            </Routes>
          </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
