import { Typography, Box, useTheme } from "@mui/material";
import SouthWestOutlinedIcon from "@mui/icons-material/SouthWestOutlined";
import { tokens } from "../theme";

const Header = ({ title, subtitle, info, variant = "h2" }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box mb="30px">
      <Typography
        variant={variant}
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{ m: "0 0 5px 0" }}
      >
        {title}
      </Typography>
      <Typography variant="body1" color={colors.grey[500]}>
        {subtitle}
      </Typography>
      {info && (
        <Box display="flex" alignItems="center" mt={1}>
          <SouthWestOutlinedIcon
            sx={{ color: colors.orangeAccent[400], marginRight: 1 }}
          />
          <Typography variant="h5" color={colors.orangeAccent[400]}>
            {info}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Header;
