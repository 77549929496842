import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import IdCardDataFetcher from "../../context/IdCardDataFetcher";
import UserDataProvider from "../../context/UserDataProvider";
import GoToTextBox from "../../components/GoToTextBox";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";

const Documents = () => {
  const theme = useTheme();
  const { userId } = useParams();
  const colors = tokens(theme.palette.mode);

  return (
    <IdCardDataFetcher userId={userId}>
      {(idCardData) => (
        <UserDataProvider userId={userId}>
          {(userData) => (
            <Box m="20px">
              {/* HEADER */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Header
                  title="Documente sofer"
                  subtitle={`Documentele pentru soferul: ${userId}`}
                />
              </Box>

              <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
              >
                <Box
                  gridColumn="span 4"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <GoToTextBox
                    title="Buletin"
                    subtitle="Click aici pentru a accesa buletinul"
                    route={`/id-card/${userId}`}
                    icon={<BadgeOutlinedIcon fontSize="large" />} // Custom icon
                  />
                </Box>
                <Box
                  gridColumn="span 4"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  Test 2
                </Box>

                <Box
                  gridColumn="span 4"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  flexDirection="column" // Așezare pe coloane
                  alignItems="center"
                  justifyContent="center"
                >
                  Test 3
                </Box>
              </Box>
            </Box>
          )}
        </UserDataProvider>
      )}
    </IdCardDataFetcher>
  );
};

export default Documents;
