import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";
import {
  getChapter,
  hasDocumentsToBeVerified,
} from "../helpers/statusCalculator";
import {
  convertToUTCISO,
  formatDateToYYYYMMDD2,
  formatDateToYYYYMMDDHHMMSS,
} from "../helpers/dateUtils";

const UserDataProvider = ({
  children,
  filterByRoleAdmin,
  userId,
  setError,
}) => {
  const [userData, setUserData] = useState([]);
  const [localError, setLocalError] = useState(""); // Changed variable name to avoid conflict

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        let url = EXTERNAL_ROUTES.USER;

        // Add userId to the URL if provided
        if (userId) {
          url += `?id=${userId}`;
        }
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response);
        let filteredData = response.data.data;

        if (filterByRoleAdmin) {
          filteredData = response.data.data.filter(
            (user) => user.user_permission === "super_admin"
          );
        } else {
          filteredData = response.data.data.filter(
            (user) => user.user_permission !== "super_admin"
          );
        }

        const formattedData = filteredData.map((user) => ({
          chapter: getChapter([user]),
          ...user,
          userId: user.id, // Autogenerate ID
          firstname: user.id_card?.first_name || "",
          lastname: user.id_card?.last_name || "",
          hasIdCard: user.id_card ? "da" : "nu",
          onboardingFinalised: user.onboarding_completed_acceptance || false,
          verified: hasDocumentsToBeVerified(user),
          updated_at: formatDateToYYYYMMDDHHMMSS(user.updated_at),
        }));
        console.log(formattedData);
        setUserData(formattedData);
      } catch (error) {
        setLocalError("Error fetching user data. Please try again."); // Set error message locally
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [filterByRoleAdmin, userId]); // Added userId to the dependency array

  return children(userData, localError); // Return userData and localError to children components
};

export default UserDataProvider;
