import {
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
  CircularProgress,
  Alert,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import IdCardDataFetcher from "../../context/IdCardDataFetcher";
import UserDataProvider from "../../context/UserDataProvider";
import GoToTextBox from "../../components/GoToTextBox";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import GarageOutlinedIcon from "@mui/icons-material/GarageOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import CarRentalOutlinedIcon from "@mui/icons-material/CarRentalOutlined";
import Tooltip from "@mui/material/Tooltip";
import { EXTERNAL_ROUTES, ROUTES } from "../../helpers/routes";
import { useState, useEffect } from "react";
import axios from "../../api/axios";
import CarDetailsFetcher from "../../context/CarDetailsFetcher";
import DownloadArrFolder from "../../context/DownloadArrFolder";
import DownloadPfaBox from "../../components/DownloadPfaBox";

const WithoutCertifiedCopy = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [mailingError, setMailingError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (emailSent) {
      const timer = setTimeout(() => {
        setEmailSent(false);
      }, 5000); // Display the alert for 5 seconds
      return () => clearTimeout(timer);
    }
  }, [emailSent]);

  const sendArrFolderEmail = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${EXTERNAL_ROUTES.ARR_FOLDER_EMAIL_SEND}?id=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        if (response.data.error && response.data.error.detail) {
          setMailingError(response.data.error.detail);
        } else {
          setMailingError(null);
          setEmailSent(true); // Indicate email was sent
        }
      } else {
        setMailingError("An error occurred while sending email.");
      }
    } catch (error) {
      console.error("Error sending email data:", error);
      setMailingError("An error occurred while sending email.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleButtonClick = () => {
    sendArrFolderEmail();
  };

  const theme = useTheme();
  const { userId } = useParams();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const handleGoBack = (userId) => {
    navigate(`${ROUTES.ARR}/${userId}`);
  };

  return (
    <IdCardDataFetcher userId={userId}>
      {(idCardData) => (
        <CarDetailsFetcher userId={userId}>
          {(carDetailsData) => (
            <UserDataProvider userId={userId}>
              {(userData) => (
                <Box m="20px">
                  {/* HEADER */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Header
                      title="User fara Copie conforma"
                      subtitle={`Date despre user fara copie conforma: ${userId}`}
                    />
                    <Box>
                      <Button
                        sx={{
                          backgroundColor: colors.primary[700],
                          color: colors.orangeAccent[100],
                          fontSize: "14px",
                          fontWeight: "bold",
                          padding: "10px 20px",
                        }}
                        onClick={() => handleGoBack(userId)}
                      >
                        <ArrowBackOutlinedIcon sx={{ mr: "10px" }} />
                        Back
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {mailingError ? (
                      <Alert severity="error">
                        Error message: {mailingError}
                      </Alert>
                    ) : emailSent ? (
                      <Alert severity="success">Email sent successfully!</Alert>
                    ) : null}
                  </Box>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridAutoRows="140px"
                    gap="20px"
                  >
                    <Box
                      gridColumn="span 4"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <GoToTextBox
                        title="Carte masina"
                        subtitle="Click aici pentru a accesa cartea masinii"
                        route={`${ROUTES.CAR_REGISTRATION}/${userId}`}
                        icon={
                          <DirectionsCarFilledOutlinedIcon fontSize="large" />
                        } // Custom icon
                        statusIcon={
                          userData.length > 0 &&
                          userData[0].user_inputs.some(
                            (input) =>
                              input.field_name === "car_identity_card" &&
                              (input.uploaded === true ||
                                input.uploaded === null) // Verifică și pentru valoarea null
                          ) ? (
                            userData[0].user_inputs.some(
                              (input) =>
                                input.field_name === "car_identity_card" &&
                                input.validated === true
                            ) ? (
                              <Tooltip
                                title="Documentul a fost încărcat și validat!"
                                arrow
                              >
                                <DoneOutlineOutlinedIcon fontSize="large" />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="Documentul a fost încărcat dar nu și validat!"
                                arrow
                              >
                                <UploadFileOutlinedIcon fontSize="large" />
                              </Tooltip>
                            )
                          ) : (
                            <Tooltip
                              title="Documentul nu a fost nici încărcat nici validat!"
                              arrow
                            >
                              <WarningOutlinedIcon fontSize="large" />
                            </Tooltip>
                          )
                        }
                      />
                    </Box>
                    <Box
                      gridColumn="span 4"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <GoToTextBox
                        title="Contract leasing"
                        subtitle="Click aici pentru a accesa datele"
                        route={`${ROUTES.LEASING_AGREEMENT}/${userId}`}
                        sourceFlow="leasing"
                        icon={<GarageOutlinedIcon fontSize="large" />} // Custom icon
                        statusIcon={
                          userData.length > 0 &&
                          userData[0].user_inputs.some(
                            (input) =>
                              input.field_name === "leasing_agreement" &&
                              (input.uploaded === true ||
                                input.uploaded === null) // Verifică și pentru valoarea null
                          ) ? (
                            userData[0].user_inputs.some(
                              (input) =>
                                input.field_name === "leasing_agreement" &&
                                input.validated === true
                            ) ? (
                              <Tooltip
                                title="Documentul a fost încărcat și validat!"
                                arrow
                              >
                                <DoneOutlineOutlinedIcon fontSize="large" />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="Documentul a fost încărcat dar nu și validat!"
                                arrow
                              >
                                <UploadFileOutlinedIcon fontSize="large" />
                              </Tooltip>
                            )
                          ) : (
                            <Tooltip
                              title="Documentul nu a fost nici încărcat nici validat!"
                              arrow
                            >
                              <WarningOutlinedIcon fontSize="large" />
                            </Tooltip>
                          )
                        }
                        status={
                          carDetailsData?.is_leased === true
                            ? "active"
                            : "inactive"
                        }
                      />
                    </Box>{" "}
                    <Box
                      gridColumn="span 4"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <GoToTextBox
                        title="Contract chirie sau comodat"
                        subtitle="Click aici pentru a accesa contract chirie/comodat"
                        icon={<CarRentalOutlinedIcon fontSize="large" />} // Custom icon
                        sourceFlow="carContract"
                        route={`${ROUTES.CAR_CONTRACT}/${userId}`}
                        status={
                          carDetailsData?.is_owned_by_pfa === true
                            ? "inactive"
                            : "active"
                        }
                        statusIcon={
                          userData.length > 0 &&
                          userData[0].user_inputs.some(
                            (input) =>
                              input.field_name === "car_contract" &&
                              (input.uploaded === true ||
                                input.uploaded === null) // Verifică și pentru valoarea null
                          ) ? (
                            userData[0].user_inputs.some(
                              (input) =>
                                input.field_name === "car_contract" &&
                                input.validated === true
                            ) ? (
                              <Tooltip
                                title="Documentul a fost încărcat și validat!"
                                arrow
                              >
                                <DoneOutlineOutlinedIcon fontSize="large" />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="Documentul a fost încărcat dar nu și validat!"
                                arrow
                              >
                                <UploadFileOutlinedIcon fontSize="large" />
                              </Tooltip>
                            )
                          ) : (
                            <Tooltip
                              title="Documentul nu a fost nici încărcat nici validat!"
                              arrow
                            >
                              <WarningOutlinedIcon fontSize="large" />
                            </Tooltip>
                          )
                        }
                      />
                    </Box>
                    <Box
                      gridColumn="span 4"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <GoToTextBox
                        title="Dovada platii copie conforma"
                        subtitle="Click aici pentru a accesa datele"
                        route={`${ROUTES.PAYMENT_PROOF_CERTIFIED_COPY}/${userId}`}
                        icon={<PaidOutlinedIcon fontSize="large" />} // Custom icon
                        statusIcon={
                          userData.length > 0 &&
                          userData[0].user_inputs.some(
                            (input) =>
                              input.field_name ===
                                "certified_copy_payment_proof" &&
                              (input.uploaded === true ||
                                input.uploaded === null) // Verifică și pentru valoarea null
                          ) ? (
                            userData[0].user_inputs.some(
                              (input) =>
                                input.field_name ===
                                  "certified_copy_payment_proof" &&
                                input.validated === true
                            ) ? (
                              <Tooltip
                                title="Documentul a fost încărcat și validat!"
                                arrow
                              >
                                <DoneOutlineOutlinedIcon fontSize="large" />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="Documentul a fost încărcat dar nu și validat!"
                                arrow
                              >
                                <UploadFileOutlinedIcon fontSize="large" />
                              </Tooltip>
                            )
                          ) : (
                            <Tooltip
                              title="Documentul nu a fost nici încărcat nici validat!"
                              arrow
                            >
                              <WarningOutlinedIcon fontSize="large" />
                            </Tooltip>
                          )
                        }
                      />
                    </Box>
                    <Box
                      gridColumn="span 4"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                      p="30px"
                    >
                      <DownloadArrFolder userId={userId}>
                        {(arrZip, errorArrZip, isLoading) => {
                          if (isLoading) {
                            return <CircularProgress color="inherit" />;
                          } else if (errorArrZip) {
                            return (
                              <Alert severity="error">
                                Error: {errorArrZip}
                              </Alert>
                            );
                          } else {
                            return (
                              <DownloadPfaBox
                                userId={userId}
                                file={arrZip}
                                title="Descarca dosar ARR"
                                subtitle="Click pentru a descarca dosar ARR!"
                                name={`${idCardData.first_name}-${idCardData.last_name}-arr.zip`}
                                icon={
                                  <CloudDownloadOutlinedIcon fontSize="large" />
                                }
                              />
                            );
                          }
                        }}
                      </DownloadArrFolder>
                    </Box>
                    <Box
                      gridColumn="span 4"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                      p="30px"
                    >
                      {" "}
                      <Box
                        width="100%"
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden" // Add overflow hidden to prevent overflowing
                        borderRadius="8px" // Rounded corners
                        boxShadow={
                          isHovered
                            ? "0px 0px 10px 5px rgba(0,0,0,0.3)"
                            : "none"
                        }
                        transition="box-shadow 0.3s" // Smooth transition for shadow
                        onClick={handleButtonClick}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          backgroundColor: colors.primary[400], // Use grey background for inactive status
                          color: colors.orangeAccent[100], // Use grey color for text for inactive status
                        }}
                      >
                        {isLoading ? (
                          <CircularProgress color="inherit" /> // Loading spinner
                        ) : (
                          <Stack
                            direction="column"
                            alignItems="center"
                            spacing={1}
                          >
                            <ForwardToInboxOutlinedIcon fontSize="large" />
                            <Box textAlign="center" p="10px">
                              <Typography
                                variant="h5"
                                fontWeight="bold"
                                mb={1}
                                fontSize="1.2rem"
                              >
                                Trimite Email Dosar ARR
                              </Typography>
                              <Typography variant="subtitle1" fontSize="1rem">
                                Click pentru a trimite dosar ARR pentur user
                              </Typography>
                            </Box>
                          </Stack>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </UserDataProvider>
          )}
        </CarDetailsFetcher>
      )}
    </IdCardDataFetcher>
  );
};

export default WithoutCertifiedCopy;
