import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";

const RcaInsuranceFetcher = ({ userId, children }) => {
  const [rcaInsuranceData, setRcaInsuranceData] = useState(null);
  const [rcaInsuranceError, setError] = useState(null);

  useEffect(() => {
    const fetchRcaInsuranceData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.RCA_INSURANCE}?id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if response contains data or error
        if (response.data && response.data.data) {
          setRcaInsuranceData(response.data.data);
          setError(null);
        } else if (response.data && response.data.error) {
          setError(response.data.error.detail);
          setRcaInsuranceData(null);
        }
      } catch (error) {
        console.error("Error fetching rca insurance data:", error);
        setError("An error occurred while fetching rca insurance data.");
        setRcaInsuranceData(null);
      }
    };

    fetchRcaInsuranceData();
  }, [userId]);

  return children(rcaInsuranceData, rcaInsuranceError);
};

export default RcaInsuranceFetcher;
