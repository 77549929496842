import { Box, Button, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import ExpenseFetcher from "../../context/ExpenseFetcher";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";

const ExpenseTable = () => {
  const { userId } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const handleGoBack = (userId) => {
    navigate(`${ROUTES.DRIVER_DETAILS}/${userId}`);
  };

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "amount",
      headerName: "Suma",
      flex: 1,
    },
    {
      field: "paymentType",
      headerName: "Tip plata",
      flex: 1,
    },
    {
      field: "issueDate",
      headerName: "Data",
      flex: 1,
    },
    {
      field: "category",
      headerName: "Categorie",
      flex: 1,
    },
  ];

  return (
    <Box m="20px">
      <Header title="Facturi aprobate" subtitle="Management facturi aprobate" />
      <Box>
        <Button
          sx={{
            backgroundColor: colors.primary[700],
            color: colors.orangeAccent[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
          onClick={() => handleGoBack(userId)}
        >
          <ArrowBackOutlinedIcon sx={{ mr: "10px" }} />
          Back
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.orangeAccent[900],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.orangeAccent[300],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.orangeAccent[300],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.orangeAccent[200]} !important`,
          },
        }}
      >
        <ExpenseFetcher userId={userId}>
          {(expenseData) => <DataGrid rows={expenseData} columns={columns} />}
        </ExpenseFetcher>
      </Box>
    </Box>
  );
};

export default ExpenseTable;
