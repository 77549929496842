export const convertExpiryDate = (expiryDate) => {
  // Decompose the date into year, month, and day
  const [year, month, day] = expiryDate.split("-");

  // Construct the date in ISO 8601 format
  const isoDate = `${year}-${month}-${day}T00:00:00Z`;

  return isoDate;
};

export const isValidExpiryDate = (dateString) => {
  // Expresia regulată pentru a verifica formatul "DD.MM.YYYY"
  const dateFormatRegex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/;

  // Verificăm dacă șirul se potrivește cu formatul dorit
  return dateFormatRegex.test(dateString);
};

export const isValidExpiryDateYYYMMDD = (dateString) => {
  // Regular expression to check the format "YYYY-MM-DD"
  const dateFormatRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

  // Check if the string matches the desired format
  return dateFormatRegex.test(dateString);
};

export const convertToUTCISO = (dateString) => {
  // Create a new Date object from the input date string
  const date = new Date(dateString);

  // Extract the year, month, and day in UTC
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  // Construct the date in ISO 8601 format with 'Z' suffix indicating UTC
  const isoUTCDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;

  return isoUTCDate;
};

export const formatDateToYYYYMMDD = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const formatDateToYYYYMMDD2 = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const formatDateToYYYYMMDDHHMMSS = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  const hour = date.getHours();

  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
};

export const convertExpiryDate2 = (expiryDate) => {
  // Descompune data în zi, lună și an
  const [day, month, year] = expiryDate.split(".");

  // Construiește data în formatul ISO 8601
  const isoDate = `${year}-${month}-${day}T00:00:00:00Z`;

  return isoDate;
};
