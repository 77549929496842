import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";
const ApproveOrRejectInput = ({ userId }) => {
  const approveInput = async (fieldName) => {
    try {
      const token = localStorage.getItem("token");
      // Payload object
      const payload = {
        user_input: {
          field_name: fieldName,
          validation_error: null,
          uploaded: true,
          validated: true,
        },
      };

      const response = await axios.put(
        `${EXTERNAL_ROUTES.ADMIN_USER}/${userId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Check if response contains data or error
      if (response.data && response.data.data) {
        return response.data.data;
      } else if (response.data && response.data.error) {
        throw new Error(response.data.error.detail);
      }
    } catch (error) {
      console.error("Error approving input:", error);
      throw new Error("An error occurred while approving input.");
    }
  };

  const rejectInputWithReason = async (fieldName, reason) => {
    try {
      const token = localStorage.getItem("token");
      // Payload object
      const payload = {
        user_input: {
          field_name: fieldName,
          validation_error: reason,
          uploaded: true,
          validated: false,
        },
      };

      const response = await axios.put(
        `${EXTERNAL_ROUTES.ADMIN_USER}/${userId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Check if response contains data or error
      if (response.data && response.data.data) {
        return response.data.data;
      } else if (response.data && response.data.error) {
        throw new Error(response.data.error.detail);
      }
    } catch (error) {
      console.error("Error rejecting input:", error);
      throw new Error("An error occurred while rejecting input.");
    }
  };

  return { approveInput, rejectInputWithReason };
};

export default ApproveOrRejectInput;
