import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import UserDataProvider from "../../context/UserDataProvider";
import IdCardDataFetcher from "../../context/IdCardDataFetcher";
import { useState } from "react";
import axios from "../../api/axios";
import { EXTERNAL_ROUTES, ROUTES } from "../../helpers/routes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ApproveOrRejectInput from "../../context/ApproveOrRejectInput";
import Alert from "@mui/material/Alert";
import PopupDialog from "../../components/PopupDialog";
import BankAccountStatementNewestFetcher from "../../context/BankAccountStatementFetcher";
import CompanyBankAccountFetcher from "../../context/CompanyBankAccountFetcher";
import FiscalCertificateDataFetcher from "../../context/FiscalCertificateDataFetcher";

const Banking = () => {
  const theme = useTheme();
  const { userId } = useParams();
  const colors = tokens(theme.palette.mode);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDataDialog, setOpenDataDialog] = useState(false);
  const [openDialogReject, setOpenDialogReject] = useState(false);
  const navigate = useNavigate();
  const [reason, setReason] = useState("");
  const [formData, setFormData] = useState({
    accountNo: "",
    bankName: "",
  });

  const [error, setError] = useState(""); // State for storing error message

  const [errors, setErrors] = useState({}); // State for storing error messages

  const validateFormData = (formData) => {
    const newErrors = {};

    setErrors(newErrors);
  };

  const handleSubmitDataInfo = (bankingData) => {
    validateFormData(formData);
    console.log(errors);
    if (
      Object.keys(errors).length !== 0 &&
      Object.values(errors).every((error) => error !== "")
    ) {
      // If there are errors and all error messages are not empty strings, return early without submitting data
      console.log("Validation failed. Cannot submit data.");
      return;
    }

    const updatedBankingData = {
      ...bankingData,
      account_no:
        formData.accountNo !== "" &&
        formData.accountNo !== bankingData.account_no
          ? formData.accountNo
          : bankingData.account_no,
      bank_name:
        formData.bankName !== "" && formData.bankName !== bankingData.bank_name
          ? formData.bankName
          : bankingData.bank_name,
    };

    console.log("Updated banking data:", updatedBankingData);
    // Reset form data after submission
    setFormData({
      accountNo: "",
      bankName: "",
    });

    // Send the updated data to the server
    sendDataToServer(updatedBankingData);
  };

  const sendDataToServer = (updatedData) => {
    // Get token from local storage
    const token = localStorage.getItem("token");

    // Define the data to be sent to the server
    const postData = {
      ...updatedData,
    };

    // Set the authorization header with the token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Adăugăm token-ul în antetul de autorizare
      },
    };
    console.log(postData);
    // Send the updated data to the server
    axios
      .post(
        `${EXTERNAL_ROUTES.COMPANY_BANK_ACCOUNT}/${userId}`,
        postData,
        config
      )
      .then((response) => {
        console.log("Data successfully sent:", response.data);
        setOpenDataDialog(true);
      })
      .catch((error) => {
        setError(error?.response?.data?.error?.detail);
      });
  };

  const handleChange = (e, field) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    // Clear the error message when user starts typing in the field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const handleAccept = async () => {
    // Call the approveInput method from ApproveOrRejectInput component
    try {
      const response = await ApproveOrRejectInput({ userId }).approveInput(
        "bank_account_statement"
      );
      console.log("Input approved:", response);

      setOpenDialog(true);
    } catch (error) {
      console.error("Error approving input:", error);
      alert("Error: " + error);
    }
  };

  const handleReject = async () => {
    // Call the rejectInputWithReason method from ApproveOrRejectInput component
    if (reason.trim() !== "") {
      try {
        const response = await ApproveOrRejectInput({
          userId,
        }).rejectInputWithReason("bank_account_statement", reason);
        console.log("Input rejected with reason:", response);

        setOpenDialogReject(true);
      } catch (error) {
        console.error("Error rejecting input:", error);
        alert("Error: " + error);
      }
    } else {
      alert("Te rog să introduci un motiv pentru refuz.");
    }
  };

  const handleGoBack = (userId) => {
    navigate(`${ROUTES.BANKING_STATS}/${userId}`);
  };

  const handleCloseDialog = () => {
    // Închide dialogul de succes
    setOpenDialog(false);
    window.location.reload();
  };

  const handleCloseDialogReject = () => {
    // Închide dialogul de succes
    setOpenDialogReject(false);
    window.location.reload();
  };

  const handleCloseDataDialog = () => {
    // Închide dialogul de succes
    setOpenDataDialog(false);
    window.location.reload();
  };

  return (
    <IdCardDataFetcher userId={userId}>
      {(idCardData) => (
        <FiscalCertificateDataFetcher userId={userId}>
          {(fiscalCertificateData) => (
            <BankAccountStatementNewestFetcher userId={userId}>
              {(bankAccountPhoto) => (
                <CompanyBankAccountFetcher userId={userId}>
                  {(bankingData, companyBankError) => (
                    <UserDataProvider userId={userId} setError={setError}>
                      {(userData) => (
                        <Box m="20px">
                          <PopupDialog
                            header="Date actualizate!"
                            content="Datele au fost actualizate cu succes!"
                            open={openDataDialog}
                            onClose={handleCloseDataDialog}
                          ></PopupDialog>
                          <PopupDialog
                            header="Document aprobat!"
                            content="Documentul a fost aprobat cu succes!"
                            open={openDialog}
                            onClose={handleCloseDialog}
                          ></PopupDialog>
                          <PopupDialog
                            header="Document refuzat!"
                            content="Documentul a fost refuzat!"
                            open={openDialogReject}
                            onClose={handleCloseDialogReject}
                          ></PopupDialog>
                          {/* HEADER */}
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Header
                              title="Date bancare"
                              subtitle={`Detaliile datelor bancare pentru user: ${userId}`}
                            />

                            <Box>
                              <Button
                                sx={{
                                  backgroundColor: colors.primary[700],
                                  color: colors.orangeAccent[100],
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  padding: "10px 20px",
                                }}
                                onClick={() => handleGoBack(userId)}
                              >
                                <ArrowBackOutlinedIcon sx={{ mr: "10px" }} />
                                Back
                              </Button>
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {/* Display error message */}
                            {error && (
                              <Alert severity="error">
                                Error message: {error}
                              </Alert>
                            )}{" "}
                          </Box>{" "}
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {companyBankError && (
                              <Alert severity="error">
                                Error message: {companyBankError}
                              </Alert>
                            )}{" "}
                          </Box>
                          <Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gridAutoRows="140px"
                            gap="20px"
                          >
                            <Box
                              gridColumn="span 4"
                              gridRow="span 4"
                              backgroundColor={colors.primary[400]}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              {bankAccountPhoto && bankAccountPhoto.file ? (
                                <img
                                  src={bankAccountPhoto.file}
                                  alt="Extras de cont"
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                  }}
                                />
                              ) : (
                                <Typography
                                  variant="h5"
                                  color={colors.orangeAccent[500]}
                                  sx={{ mt: "15px" }}
                                >
                                  No picture
                                </Typography>
                              )}
                            </Box>
                            <Box
                              gridColumn="span 4"
                              gridRow="span 4"
                              backgroundColor={colors.primary[400]}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Grid
                                container
                                justifyContent="center"
                                spacing={2}
                              >
                                <Grid item xs={11}>
                                  <Typography
                                    variant="body1"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    Nume: {idCardData?.last_name || ""}
                                  </Typography>
                                  <Box height="20px" />{" "}
                                  {/* Spațiu între rânduri */}
                                  <Typography
                                    variant="body1"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    Prenume: {idCardData?.first_name || ""}
                                  </Typography>
                                  <Box height="20px" />
                                  {/* Spațiu între rânduri */}
                                  <Typography
                                    variant="body1"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    CUI: {fiscalCertificateData?.cui || ""}
                                  </Typography>
                                  <Box height="20px" />{" "}
                                  {/* Spațiu între rânduri */}
                                </Grid>
                              </Grid>
                            </Box>
                            <Box
                              gridColumn="span 4"
                              gridRow="span 4"
                              backgroundColor={colors.primary[400]}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Grid
                                container
                                justifyContent="center"
                                spacing={2}
                              >
                                <Grid item xs={11}>
                                  <Typography
                                    variant="body1"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    IBAN: {bankingData?.account_no || ""}
                                  </Typography>
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                  >
                                    <Grid item xs={8}>
                                      <TextField
                                        label="IBAN"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.accountNo}
                                        onChange={(e) =>
                                          handleChange(e, "accountNo")
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                  <Box height="20px" />{" "}
                                  {/* Spațiu între rânduri */}
                                  <Typography
                                    variant="body1"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    Nume banca: {bankingData?.bank_name || ""}
                                  </Typography>
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                  >
                                    <Grid item xs={8}>
                                      <TextField
                                        label="Nume banca"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.bankName}
                                        onChange={(e) =>
                                          handleChange(e, "bankName")
                                        }
                                        error={!!errors.bankName} // Setează eroarea pe baza existenței erorii
                                        helperText={errors.bankName} // Afișează textul erorii
                                      />
                                    </Grid>
                                  </Grid>
                                  <Box height="20px" />{" "}
                                  {/* Spațiu între rânduri */}
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                  >
                                    <Grid item xs={4}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                          handleSubmitDataInfo(bankingData)
                                        } // Pass idCardData to handleSubmitDataInfo
                                        style={{ height: "100%" }} // Înălțimea butonului este setată pe 100% pentru a se potrivi cu înălțimea câmpului de introducere
                                      >
                                        Schimbă
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>

                            <Box
                              gridColumn="span 4"
                              gridRow="span 4"
                              backgroundColor={colors.primary[400]}
                              display="flex"
                              flexDirection="column" // Așezare pe coloane
                              alignItems="center"
                              justifyContent="center"
                            >
                              {/* Rândul pentru butonul Accept */}
                              <Grid
                                container
                                justifyContent="center"
                                spacing={2}
                              >
                                <Grid item xs={11}>
                                  {/* Utilizăm toată lățimea disponibilă */}
                                  <Button
                                    variant="contained"
                                    size="large" // Mărime mai mare pentru buton
                                    fullWidth // Butonul ocupă întreaga lățime disponibilă
                                    style={{
                                      backgroundColor: colors.orangeAccent[400],
                                    }}
                                    onClick={handleAccept}
                                  >
                                    Acceptă
                                  </Button>
                                </Grid>
                              </Grid>

                              {/* Spațiu între butoane */}
                              <Box height="20px" />

                              {/* Rândul pentru butonul Refuz */}
                              <Grid
                                container
                                justifyContent="center"
                                spacing={2}
                              >
                                <Grid item xs={11}>
                                  {/* Utilizăm toată lățimea disponibilă */}
                                  <Button
                                    variant="contained"
                                    size="large" // Mărime mai mare pentru buton
                                    fullWidth // Butonul ocupă întreaga lățime disponibilă
                                    style={{
                                      backgroundColor: colors.redAccent[800],
                                    }}
                                    onClick={handleReject}
                                  >
                                    Refuză
                                  </Button>
                                </Grid>
                              </Grid>

                              {/* Spațiu între butoane */}
                              <Box height="20px" />

                              {/* Rândul pentru câmpul de text Motivul refuzului */}
                              <Grid
                                container
                                justifyContent="center"
                                spacing={2}
                              >
                                <Grid item xs={11}>
                                  {/* Utilizăm toată lățimea disponibilă */}
                                  <TextField
                                    label="Motivul refuzului"
                                    variant="outlined"
                                    multiline
                                    rows={10}
                                    fullWidth // Text field-ul ocupă întreaga lățime disponibilă
                                    size="large" // Mărime mai mare pentru text field
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                  />
                                </Grid>
                              </Grid>

                              {/* Spațiu între butoane */}
                              <Box height="20px" />
                              {/* Display validation error under text area */}
                              {userData[0]?.user_inputs &&
                                userData[0]?.user_inputs.map((input) => {
                                  if (
                                    input.field_name ===
                                      "bank_account_statement" &&
                                    input.validated === false &&
                                    input.validation_error
                                  ) {
                                    return (
                                      <Grid
                                        container
                                        justifyContent="center"
                                        spacing={2}
                                      >
                                        <Grid item xs={11}>
                                          <Accordion defaultCollapsed>
                                            <AccordionSummary
                                              expandIcon={<ExpandMoreIcon />}
                                            >
                                              <Typography
                                                color={colors.orangeAccent[500]}
                                                variant="h5"
                                              >
                                                Ultimul motiv de refuz
                                              </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                              <Typography
                                                key={input.field_name}
                                              >
                                                {input.validation_error}
                                              </Typography>
                                            </AccordionDetails>
                                          </Accordion>
                                        </Grid>
                                      </Grid>
                                    );
                                  }
                                  return null;
                                })}
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </UserDataProvider>
                  )}
                </CompanyBankAccountFetcher>
              )}
            </BankAccountStatementNewestFetcher>
          )}
        </FiscalCertificateDataFetcher>
      )}
    </IdCardDataFetcher>
  );
};

export default Banking;
