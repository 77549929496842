import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";
import UserDataProvider from "../../context/UserDataProvider";
import { useTheme } from "@emotion/react";

const Drivers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  // State for filter model, page, and page size
  const [filterModel, setFilterModel] = useState({
    items: JSON.parse(localStorage.getItem("filterModel")) || [],
  });
  const [page, setPage] = useState(Number(localStorage.getItem("page")) || 0);
  const [pageSize, setPageSize] = useState(
    Number(localStorage.getItem("pageSize")) || 10
  );

  // Save filter model, page, and page size to local storage on change
  useEffect(() => {
    localStorage.setItem("filterModel", JSON.stringify(filterModel.items));
  }, [filterModel]);

  useEffect(() => {
    localStorage.setItem("page", page.toString());
  }, [page]);

  useEffect(() => {
    localStorage.setItem("pageSize", pageSize.toString());
  }, [pageSize]);

  // Handle filter, page, and page size changes
  const handleFilterChange = (model) => {
    setFilterModel(model);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0); // Reset to the first page when page size changes
  };

  const handleClearFilters = () => {
    const clearFilterModel = { items: [] };
    setFilterModel(clearFilterModel);
    localStorage.setItem("filterModel", JSON.stringify(clearFilterModel.items));
  };

  const renderEmailVerified = (params) => {
    if (params.value === true) {
      return params.value.toString();
    } else {
      return "false";
    }
  };

  const columns = [
    { field: "userId", headerName: "ID", flex: 0.1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone", headerName: "Nr Telefon", flex: 0.8 },
    { field: "hasIdCard", headerName: "Are Buletin", flex: 0.5 },
    { field: "chapter", headerName: "Capitol", flex: 0.5 },
    { field: "verified", headerName: "Are de verificat", flex: 0.5 },
    { field: "onboardingFinalised", headerName: "Aprobat", flex: 0.5 },
    {
      field: "firstname",
      headerName: "Prenume",
      flex: 0.8,
      cellClassName: "name-column--cell",
    },
    {
      field: "lastname",
      headerName: "Nume",
      flex: 0.8,
      cellClassName: "name-column--cell",
    },
    { field: "working_city", headerName: "Oras de lucru", flex: 0.8 },
    {
      field: "email_verified",
      headerName: "Email Verificat",
      flex: 0.5,
      renderCell: renderEmailVerified,
    },
    { field: "updated_at", headerName: "Ultima modificare", flex: 1.2 },
    {
      field: "dashboard",
      headerName: "Dashboard",
      flex: 1,
      renderCell: ({ row }) => (
        <Box
          width="80%"
          m="0 auto"
          p="5px"
          display="flex"
          justifyContent="center"
          backgroundColor={colors.redAccent[800]}
          borderRadius="4px"
          onClick={() => handleDashboardClick(row.id)}
        >
          <SpaceDashboardOutlinedIcon />
          <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
            Dashboard
          </Typography>
        </Box>
      ),
    },
  ];

  const handleDashboardClick = (id) => {
    navigate(`${ROUTES.DRIVER_DETAILS}/${id}`);
  };

  return (
    <Box m="20px">
      <Header title="Soferi" subtitle="Lista soferilor" />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="20px"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleClearFilters}
        >
          Clear Filters
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.orangeAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <UserDataProvider filterByRoleAdmin={false}>
          {(userData) => (
            <DataGrid
              rows={userData}
              columns={columns}
              filterModel={filterModel}
              onFilterModelChange={handleFilterChange}
              page={page}
              onPageChange={(newPage) => handlePageChange(newPage)}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) =>
                handlePageSizeChange(newPageSize)
              }
              pagination
              paginationMode="client"
              pageSizeOptions={[10, 25, 50, 100]}
            />
          )}
        </UserDataProvider>
      </Box>
    </Box>
  );
};

export default Drivers;
