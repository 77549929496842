// ExpenseFetcher.js
import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";

const ExpensesNotApprovedFetcher = ({ children, userId }) => {
  const [expensesData, setExpensesData] = useState([]);
  const [localError, setLocalError] = useState("");
  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const token = localStorage.getItem("token");
        let url = EXTERNAL_ROUTES.USER;

        // Add userId to the URL if provided
        if (userId) {
          url += `?id=${userId}`;
        }

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(response);

        const userData = response.data.data[0] || {};
        const userInputs = userData.user_inputs || [];

        const expenses = userInputs
          .filter(
            (input) =>
              input.field_name.startsWith("expense") && !input.validated
          ) // Filter expenses with validated set to false
          .map((expense) => ({
            id: expense.field_name.split("_").pop(), // Extract ID from field name
            created_at: new Date(expense.created_at)
              .toISOString()
              .split("T")[0], // Format date to YYYY-MM-DD
          }));

        setExpensesData(expenses);
      } catch (error) {
        setLocalError("Error fetching user data. Please try again.");
        console.error("Error fetching user data:", error);
      }
    };

    fetchExpenses();
  }, [userId]);

  return children(expensesData, localError);
};

export default ExpensesNotApprovedFetcher;
