import { IconButton, Tooltip } from "@mui/material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../helpers/routes";
import useAuth from "../hooks/useAuth";

const LogoutButton = () => {
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const handleLogout = () => {
    // Apelăm funcția de delogare primită ca prop
    localStorage.removeItem("token");
    setAuth([]);
    navigate(`${ROUTES.LOGIN}`);
  };

  return (
    <Tooltip title="Logout">
      <IconButton onClick={handleLogout}>
        <LogoutOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
};

export default LogoutButton;
