import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";

const MedicalTestsFetcher = ({ userId, children }) => {
  const [medicalTestsData, setMedicalTestsData] = useState(null);
  const [medicalTestsError, setError] = useState(null);

  useEffect(() => {
    const fetchMedicalTestsData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.MEDICAL_TESTS}?id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if response contains data or error
        if (response.data && response.data.data) {
          setMedicalTestsData(response.data.data);
          setError(null);
        } else if (response.data && response.data.error) {
          setError(response.data.error.detail);
          setMedicalTestsData(null);
        }
      } catch (error) {
        console.error("Error fetching medical tests data:", error);
        setError("An error occurred while fetching medical tests data.");
        setMedicalTestsData(null);
      }
    };

    fetchMedicalTestsData();
  }, [userId]);

  return children(medicalTestsData, medicalTestsError);
};

export default MedicalTestsFetcher;
