import { Alert, Box, Button, CircularProgress, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import IdCardDataFetcher from "../../context/IdCardDataFetcher";
import UserDataProvider from "../../context/UserDataProvider";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import GoToTextBox from "../../components/GoToTextBox";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import Tooltip from "@mui/material/Tooltip";
import { ROUTES } from "../../helpers/routes";
import DownloadSpvCnpFolder from "../../context/DownloadSpvCnpFolder";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import DownloadPfaBox from "../../components/DownloadPfaBox";
import DownloadSpvCuiFolder from "../../context/DownloadSpvCuiFolder";
import FiscalCertificateDataFetcher from "../../context/FiscalCertificateDataFetcher";

const Pfa = () => {
  const theme = useTheme();
  const { userId } = useParams();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const handleGoBack = (userId) => {
    navigate(`${ROUTES.DRIVER_DETAILS}/${userId}`);
  };

  return (
    <IdCardDataFetcher userId={userId}>
      {(idCardData) => (
        <UserDataProvider userId={userId}>
          {(userData) => (
            <FiscalCertificateDataFetcher userId={userId}>
              {(fiscalCertificateData, fiscalCertificateError) => (
                <Box m="20px">
                  {/* HEADER */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Header
                      title="PFA sofer"
                      subtitle={`Date despre PFA pentru user: ${userId}`}
                    />
                    <Box>
                      <Button
                        sx={{
                          backgroundColor: colors.primary[700],
                          color: colors.orangeAccent[100],
                          fontSize: "14px",
                          fontWeight: "bold",
                          padding: "10px 20px",
                        }}
                        onClick={() => handleGoBack(userId)}
                      >
                        <ArrowBackOutlinedIcon sx={{ mr: "10px" }} />
                        Back
                      </Button>
                    </Box>
                  </Box>

                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridAutoRows="140px"
                    gap="20px"
                  >
                    <Box
                      gridColumn="span 4"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <GoToTextBox
                        title="Fara PFA"
                        subtitle="Click aici pentru a salva dosar"
                        route={`${ROUTES.DRIVER_WITHOUT_PFA}/${userId}`}
                        icon={<NoteAddOutlinedIcon fontSize="large" />} // Custom icon
                        sourceFlow="withoutPfa"
                        status={fiscalCertificateData ? "inactive" : "active"}
                      />
                    </Box>
                    <Box
                      gridColumn="span 4"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <GoToTextBox
                        title="Cu PFA"
                        subtitle="Click aici pentru a accesa datele"
                        route={`${ROUTES.DRIVER_WITH_PFA}/${userId}`}
                        icon={<GradingOutlinedIcon fontSize="large" />} // Custom icon
                        statusIcon={
                          userData.length > 0 &&
                          userData[0].user_inputs.some(
                            (input) =>
                              input.field_name === "fiscal_certificate" &&
                              (input.uploaded === true ||
                                input.uploaded === null) // Verifică și pentru valoarea null
                          ) ? (
                            userData[0].user_inputs.some(
                              (input) =>
                                input.field_name === "fiscal_certificate" &&
                                input.validated === true
                            ) ? (
                              <Tooltip
                                title="Documentul a fost încărcat și validat!"
                                arrow
                              >
                                <DoneOutlineOutlinedIcon fontSize="large" />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="Documentul a fost încărcat dar nu și validat!"
                                arrow
                              >
                                <UploadFileOutlinedIcon fontSize="large" />
                              </Tooltip>
                            )
                          ) : (
                            <Tooltip
                              title="Documentul nu a fost nici încărcat nici validat!"
                              arrow
                            >
                              <WarningOutlinedIcon fontSize="large" />
                            </Tooltip>
                          )
                        }
                      />
                    </Box>
                    <Box
                      gridColumn="span 4"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                      p="30px"
                    >
                      {" "}
                      <DownloadSpvCnpFolder userId={userId}>
                        {(spvCnpZip, errorSpvCnpZip, isLoading) => {
                          if (isLoading) {
                            return <CircularProgress color="inherit" />;
                          } else if (errorSpvCnpZip) {
                            return (
                              <Alert severity="error">
                                Error: {errorSpvCnpZip}
                              </Alert>
                            );
                          } else {
                            return (
                              <DownloadPfaBox
                                userId={userId}
                                file={spvCnpZip}
                                title="Descarca dosar Spv CNP"
                                subtitle="Click pentru a descarca dosar SPV CNP!"
                                name="spv-cnp.zip"
                                icon={
                                  <CloudDownloadOutlinedIcon fontSize="large" />
                                }
                              />
                            );
                          }
                        }}
                      </DownloadSpvCnpFolder>
                    </Box>
                    <Box
                      gridColumn="span 4"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                      p="30px"
                    >
                      {" "}
                      <DownloadSpvCuiFolder userId={userId}>
                        {(spvCuiZip, errorSpvCuiZip, isLoading) => {
                          if (isLoading) {
                            return <CircularProgress color="inherit" />;
                          } else if (errorSpvCuiZip) {
                            return (
                              <Alert severity="error">
                                Error: {errorSpvCuiZip}
                              </Alert>
                            );
                          } else {
                            return (
                              <DownloadPfaBox
                                userId={userId}
                                file={spvCuiZip}
                                title="Descarca dosar Spv CUI"
                                subtitle="Click pentru a descarca dosar SPV CUI!"
                                name={`${idCardData.first_name}-${idCardData.last_name}-spv-cui.zip`}
                                icon={
                                  <CloudDownloadOutlinedIcon fontSize="large" />
                                }
                              />
                            );
                          }
                        }}
                      </DownloadSpvCuiFolder>
                    </Box>
                  </Box>
                </Box>
              )}
            </FiscalCertificateDataFetcher>
          )}
        </UserDataProvider>
      )}
    </IdCardDataFetcher>
  );
};

export default Pfa;
