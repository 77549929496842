// DTO pentru răspunsul de statistică
class StatisticReportDTO {
  constructor(data) {
    this.time_period_start = data?.time_period_start || null;
    this.time_period_end = data?.time_period_end || null;
    this.subscription_fee = data?.subscription_fee || 0;
    this.uber = data?.uber ? new UberDTO(data.uber) : null;
    this.bolt = data?.bolt ? new BoltDTO(data.bolt) : null;
    this.total = new TotalDTO(data);
  }
}

// DTO pentru datele Uber
class UberDTO {
  constructor(data) {
    this.currency = data?.currency || "";
    this.income = new IncomeDTO(data?.income);
    this.expense = new ExpenseDTO(data?.expense);
  }
}

// DTO pentru datele Bolt
class BoltDTO {
  constructor(data) {
    this.currency = data?.currency || "";
    this.income = new IncomeDTO(data?.income);
    this.expense = new ExpenseDTO(data?.expense);
  }
}

// DTO pentru venituri și cheltuieli
class IncomeDTO {
  constructor(data) {
    this.gross_earnings = data?.gross_earnings || 0;
    this.card_earnings = data?.card_earnings || 0;
    this.cash_earnings = data?.cash_earnings || 0;
    this.fare = data?.fare || 0;
    this.bonus_amount = data?.bonus_amount || 0;
    this.cancelling_fee = data?.cancelling_fee || 0;
    this.net_amount = data?.net_amount || 0;
    this.tips = data?.tips || 0;
    this.profit = (data?.net_amount || 0) + (data?.platform_fee || 0);
  }
}

class TotalDTO {
  constructor(data) {
    this.total_profit =
      (data?.uber?.income?.net_amount || 0) +
      (data?.bolt?.income?.net_amount || 0);
    this.total_expenses =
      (data?.uber?.expense?.platform_fee || 0) +
      (data?.bolt?.expense?.platform_fee || 0);
    this.total_gross_earnings =
      (data?.uber?.income?.gross_earnings || 0) +
      (data?.bolt?.income?.gross_earnings || 0);
  }
}

// DTO pentru cheltuieli
class ExpenseDTO {
  constructor(data) {
    this.platform_fee = data?.platform_fee || 0;
  }
}

export default StatisticReportDTO;
