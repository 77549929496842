import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";
import AccountingDTO from "../helpers/AccountingDto";

const AccountingDataFetcher = ({ userId, children }) => {
  const [accountingData, setAccountingData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAccountingData = async () => {
      try {
        const currentDate = new Date();
        const firstDayOfLastMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          0
        );

        const formattedFirstDayOfLastMonth = `${firstDayOfLastMonth.getFullYear()}-${(
          firstDayOfLastMonth.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${firstDayOfLastMonth
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        const formattedLastDayOfLastMonth = `${lastDayOfLastMonth.getFullYear()}-${(
          lastDayOfLastMonth.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${lastDayOfLastMonth
          .getDate()
          .toString()
          .padStart(2, "0")}`;

        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.FISCAL_REPORT}/${userId}?start_date=${formattedFirstDayOfLastMonth}&end_date=${formattedLastDayOfLastMonth}&time_period_type=monthly`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Verifică dacă răspunsul conține date sau eroare
        if (response.data && response.data.data) {
          // Creează un nou obiect DTO cu datele răspunsului
          const accountingDto = new AccountingDTO(response.data.data);

          setAccountingData(accountingDto);
          setError(null);
        } else if (response.data && response.data.error) {
          setError(response.data.error.detail);
          setAccountingData(null);
        }
      } catch (error) {
        console.error("Error fetching ccounting/fiscal data:", error);
        setError("An error occurred while fetching accounting/fiscal data.");
        setAccountingData(null);
      }
    };

    fetchAccountingData();
  }, [userId, error]);
  return children(accountingData);
};

export default AccountingDataFetcher;
