import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import useCheckTokenExpiry from "../hooks/useCheckTokenExpiry";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { auth, setAuth } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const expirationTime = localStorage.getItem("expirationTime");

    if (token && expirationTime) {
      setAuth({ isLogged: true, token });
    }
    setIsLoading(false);
  }, [setAuth]);

  useCheckTokenExpiry();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <Outlet />;
};

export default PersistLogin;
