import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import UserDataProvider from "../../context/UserDataProvider";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const userId = localStorage.getItem("userId");

  return (
    <div style={{ display: "flex" }}>
      {/* Bara laterală */}
      <div
        style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          zIndex: 999,
        }}
      >
        <UserDataProvider userId={userId} filterByRoleAdmin={true}>
          {(userData) => (
            <Box
              sx={{
                "& .pro-sidebar-inner": {
                  background: `${colors.primary[400]} !important`,
                },
                "& .pro-icon-wrapper": {
                  backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                  padding: "5px 35px 5px 20px !important",
                },
                "& .pro-inner-item:hover": {
                  color: "#F29237 !important",
                },
                "& .pro-menu-item.active": {
                  color: "#F29237 !important",
                },
              }}
            >
              <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                  {/* LOGO AND MENU ICON */}
                  <MenuItem
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                    style={{
                      margin: "10px 0 20px 0",
                      color: colors.grey[100],
                    }}
                  >
                    {!isCollapsed && (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        ml="15px"
                      >
                        <Typography variant="h3" color={colors.grey[100]}>
                          PICKADMIN
                        </Typography>
                        <IconButton
                          onClick={() => setIsCollapsed(!isCollapsed)}
                        >
                          <MenuOutlinedIcon />
                        </IconButton>
                      </Box>
                    )}
                  </MenuItem>

                  {!isCollapsed && (
                    <Box mb="25px">
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <img
                          alt="profile-user"
                          width="100px"
                          height="100px"
                          src={`../../assets/pick-logo.png`}
                          style={{ cursor: "pointer", borderRadius: "50%" }}
                        />
                      </Box>
                      <Box textAlign="center">
                        <Typography
                          variant="h2"
                          color={colors.grey[100]}
                          fontWeight="bold"
                          sx={{ m: "10px 0 0 0" }}
                        >
                          {userData?.firstname || "Admin User"}
                        </Typography>
                        <Typography
                          variant="h9"
                          color={colors.orangeAccent[500]}
                        >
                          {userData.length > 0 && userData[0].email
                            ? userData[0].email
                            : "Email inexistent"}
                        </Typography>
                      </Box>
                    </Box>
                  )}

                  <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                    <Item
                      title="Dashboard"
                      to="/"
                      icon={<HomeOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Typography
                      variant="h6"
                      color={colors.grey[300]}
                      sx={{ m: "15px 0 5px 20px" }}
                    >
                      Data
                    </Typography>
                    <Item
                      title="Admin users"
                      to="/team"
                      icon={<PeopleOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title="Drivers"
                      to="/drivers"
                      icon={<ContactsOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Typography
                      variant="h6"
                      color={colors.grey[300]}
                      sx={{ m: "15px 0 5px 20px" }}
                    >
                      Pages
                    </Typography>
                    <Item
                      title="FAQ Page"
                      to="/faq"
                      icon={<HelpOutlineOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Box height="1000px" /> {/* Spațiu între rânduri */}
                  </Box>
                </Menu>
              </ProSidebar>
            </Box>
          )}
        </UserDataProvider>
      </div>
      {/* Conținutul din dreapta */}
    </div>
  );
};

export default Sidebar;
