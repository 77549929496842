import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import UserDataProvider from "../../context/UserDataProvider";
import { useState } from "react";
import { ROUTES } from "../../helpers/routes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ApproveOrRejectInput from "../../context/ApproveOrRejectInput";
import Alert from "@mui/material/Alert";
import PopupDialog from "../../components/PopupDialog";
import CarContractFetcher from "../../context/CarContractFetcher";

const CarContract = () => {
  const theme = useTheme();
  const { userId } = useParams();
  const colors = tokens(theme.palette.mode);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDataDialog, setOpenDataDialog] = useState(false);
  const [openDialogReject, setOpenDialogReject] = useState(false);
  const navigate = useNavigate();
  const [reason, setReason] = useState("");
  const [formData, setFormData] = useState({
    expiryDate: "",
  });

  const [formDataCar, setFormDataCar] = useState({
    licensePlate: "",
  });

  const [error, setError] = useState(""); // State for storing error message

  const handleAccept = async () => {
    // Call the approveInput method from ApproveOrRejectInput component
    try {
      const response = await ApproveOrRejectInput({ userId }).approveInput(
        "car_contract"
      );
      console.log("Input approved:", response);

      setOpenDialog(true);
    } catch (error) {
      console.error("Error approving input:", error);
      alert("Error: " + error);
    }
  };

  const handleReject = async () => {
    // Call the rejectInputWithReason method from ApproveOrRejectInput component
    if (reason.trim() !== "") {
      try {
        const response = await ApproveOrRejectInput({
          userId,
        }).rejectInputWithReason("car_contract", reason);
        console.log("Input rejected with reason:", response);

        setOpenDialogReject(true);
      } catch (error) {
        console.error("Error rejecting input:", error);
        alert("Error: " + error);
      }
    } else {
      alert("Te rog să introduci un motiv pentru refuz.");
    }
  };

  const handleGoBack = (userId) => {
    navigate(`${ROUTES.WITHOUT_CERTIFIED_COPY}/${userId}`);
  };

  const handleCloseDialog = () => {
    // Închide dialogul de succes
    setOpenDialog(false);
    window.location.reload();
  };

  const handleCloseDialogReject = () => {
    // Închide dialogul de succes
    setOpenDialogReject(false);
    window.location.reload();
  };

  const handleCloseDataDialog = () => {
    // Închide dialogul de succes
    setOpenDataDialog(false);
    window.location.reload();
  };

  return (
    <CarContractFetcher userId={userId}>
      {(carContractData, carContractError) => (
        <UserDataProvider userId={userId} setError={setError}>
          {(userData) => (
            <Box m="20px">
              <PopupDialog
                header="Date actualizate!"
                content="Datele au fost actualizate cu succes!"
                open={openDataDialog}
                onClose={handleCloseDataDialog}
              ></PopupDialog>
              <PopupDialog
                header="Document aprobat!"
                content="Documentul a fost aprobat cu succes!"
                open={openDialog}
                onClose={handleCloseDialog}
              ></PopupDialog>
              <PopupDialog
                header="Document refuzat!"
                content="Documentul a fost refuzat!"
                open={openDialogReject}
                onClose={handleCloseDialogReject}
              ></PopupDialog>
              {/* HEADER */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Header
                  title="Contract masina"
                  subtitle={`Detaliile contractului masinii pentru user: ${userId}`}
                />

                <Box>
                  <Button
                    sx={{
                      backgroundColor: colors.primary[700],
                      color: colors.orangeAccent[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                    onClick={() => handleGoBack(userId)}
                  >
                    <ArrowBackOutlinedIcon sx={{ mr: "10px" }} />
                    Back
                  </Button>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {/* Display error message */}
                {error && (
                  <Alert severity="error">Error message: {error}</Alert>
                )}{" "}
              </Box>{" "}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {/* Display error message */}
                {carContractError && (
                  <Alert severity="error">
                    Error message: {carContractError}
                  </Alert>
                )}{" "}
              </Box>{" "}
              <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
              >
                {carContractData &&
                  carContractData.map((contract, index) => (
                    <Box
                      key={index}
                      gridColumn="span 4"
                      gridRow="span 4"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {contract.file ? (
                        <img
                          src={contract.file}
                          alt={`Contract ${index + 1}`}
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      ) : (
                        <Typography
                          variant="h5"
                          color={colors.orangeAccent[500]}
                          sx={{ mt: "15px" }}
                        >
                          No picture
                        </Typography>
                      )}
                    </Box>
                  ))}

                <Box
                  gridColumn="span 4"
                  gridRow="span 4"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  flexDirection="column" // Așezare pe coloane
                  alignItems="center"
                  justifyContent="center"
                >
                  {/* Rândul pentru butonul Accept */}
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={11}>
                      {/* Utilizăm toată lățimea disponibilă */}
                      <Button
                        variant="contained"
                        size="large" // Mărime mai mare pentru buton
                        fullWidth // Butonul ocupă întreaga lățime disponibilă
                        style={{
                          backgroundColor: colors.orangeAccent[400],
                        }}
                        onClick={handleAccept}
                      >
                        Acceptă
                      </Button>
                    </Grid>
                  </Grid>

                  {/* Spațiu între butoane */}
                  <Box height="20px" />

                  {/* Rândul pentru butonul Refuz */}
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={11}>
                      {/* Utilizăm toată lățimea disponibilă */}
                      <Button
                        variant="contained"
                        size="large" // Mărime mai mare pentru buton
                        fullWidth // Butonul ocupă întreaga lățime disponibilă
                        style={{
                          backgroundColor: colors.redAccent[800],
                        }}
                        onClick={handleReject}
                      >
                        Refuză
                      </Button>
                    </Grid>
                  </Grid>

                  {/* Spațiu între butoane */}
                  <Box height="20px" />

                  {/* Rândul pentru câmpul de text Motivul refuzului */}
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={11}>
                      {/* Utilizăm toată lățimea disponibilă */}
                      <TextField
                        label="Motivul refuzului"
                        variant="outlined"
                        multiline
                        rows={10}
                        fullWidth // Text field-ul ocupă întreaga lățime disponibilă
                        size="large" // Mărime mai mare pentru text field
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  {/* Spațiu între butoane */}
                  <Box height="20px" />
                  {/* Display validation error under text area */}
                  {userData[0]?.user_inputs &&
                    userData[0]?.user_inputs.map((input) => {
                      if (
                        input.field_name === "car_contract" &&
                        input.validated === false &&
                        input.validation_error
                      ) {
                        return (
                          <Grid container justifyContent="center" spacing={2}>
                            <Grid item xs={11}>
                              <Accordion defaultCollapsed>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <Typography
                                    color={colors.orangeAccent[500]}
                                    variant="h5"
                                  >
                                    Ultimul motiv de refuz
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography key={input.field_name}>
                                    {input.validation_error}
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          </Grid>
                        );
                      }
                      return null;
                    })}
                </Box>
              </Box>
            </Box>
          )}
        </UserDataProvider>
      )}
    </CarContractFetcher>
  );
};

export default CarContract;
