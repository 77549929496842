import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";

const InfoBox = ({ title, subtitle, description, icon }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="0 auto">
      <Box textAlign="center" mb={1}>
        {icon}
      </Box>
      <Box textAlign="center">
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ color: colors.grey[100] }}
        >
          {title}
        </Typography>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ color: colors.orangeAccent[500] }}
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoBox;
