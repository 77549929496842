import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import IdCardDataFetcher from "../../context/IdCardDataFetcher";
import UserDataProvider from "../../context/UserDataProvider";
import GoToTextBox from "../../components/GoToTextBox";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import AccessibilityOutlinedIcon from "@mui/icons-material/AccessibilityOutlined";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import Tooltip from "@mui/material/Tooltip";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import { ROUTES } from "../../helpers/routes";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";

const Onboarding = () => {
  const theme = useTheme();
  const { userId } = useParams();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const getStatus = (userData) => {
    if (userData.length <= 0 || !userData[0].user_inputs.length) {
      return "not-uploaded";
    }

    const allInputsValidated = ["profile_picture", "personal_data"].every(
      (fieldName) =>
        userData[0].user_inputs.some(
          (input) => input.field_name === fieldName && input.validated
        )
    );

    const allInputsUploaded = ["profile_picture", "personal_data"].every(
      (fieldName) =>
        userData[0].user_inputs.some(
          (input) => input.field_name === fieldName && input.uploaded
        )
    );

    // Check if "id_card" input is not validated and has a validation error
    const profilePicWithError = userData[0].user_inputs.some(
      (input) =>
        input.field_name === "profile_picture" &&
        input.validated === false &&
        input.validation_error
    );

    // If id_card input is not validated and has a validation error, return "error" status
    if (profilePicWithError) {
      return "error";
    }

    if (allInputsUploaded && allInputsValidated) {
      return "validated";
    }

    if (allInputsUploaded) {
      return "uploaded";
    }

    return "not-uploaded";
  };

  const handleGoBack = (userId) => {
    navigate(`${ROUTES.DRIVER_DETAILS}/${userId}`);
  };

  return (
    <IdCardDataFetcher userId={userId}>
      {(idCardData) => (
        <UserDataProvider userId={userId}>
          {(userData) => (
            <Box m="20px">
              {/* HEADER */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Header
                  title="Date inregistrare"
                  subtitle={`Date inregistrare pentru soferul: ${userId}`}
                />
                <Box>
                  <Button
                    sx={{
                      backgroundColor: colors.primary[700],
                      color: colors.orangeAccent[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                    onClick={() => handleGoBack(userId)}
                  >
                    <ArrowBackOutlinedIcon sx={{ mr: "10px" }} />
                    Back
                  </Button>
                </Box>
              </Box>

              <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
              >
                <Box
                  gridColumn="span 4"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <GoToTextBox
                    title="Buletin"
                    subtitle="Click aici pentru a accesa buletinul"
                    route={`${ROUTES.ID_CARD}/${userId}`}
                    icon={<DirectionsCarOutlinedIcon fontSize="large" />} // Custom icon
                    statusIcon={
                      userData.length > 0 &&
                      userData[0].user_inputs &&
                      userData[0].user_inputs.some(
                        (input) =>
                          input.field_name === "id_card" &&
                          (input.uploaded === true || input.uploaded === null)
                      ) ? (
                        userData[0].user_inputs &&
                        userData[0].user_inputs.some(
                          (input) =>
                            input.field_name === "id_card" &&
                            input.validated === true
                        ) ? (
                          <Tooltip
                            title="Documentul a fost încărcat și validat!"
                            arrow
                          >
                            <DoneOutlineOutlinedIcon fontSize="large" />
                          </Tooltip>
                        ) : userData[0].user_inputs.some(
                            (input) =>
                              input.field_name === "id_card" &&
                              input.validated === false &&
                              input.validation_error
                          ) ? (
                          <Tooltip title="Documentul a fost refuzat!" arrow>
                            <DangerousOutlinedIcon fontSize="large" />
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title="Documentul a fost încărcat dar nu și validat!"
                            arrow
                          >
                            <UploadFileOutlinedIcon fontSize="large" />
                          </Tooltip>
                        )
                      ) : (
                        <Tooltip
                          title="Documentul nu a fost nici încărcat nici validat!"
                          arrow
                        >
                          <WarningOutlinedIcon fontSize="large" />
                        </Tooltip>
                      )
                    }
                  />
                </Box>
                <Box
                  gridColumn="span 4"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <GoToTextBox
                    title="Permis"
                    subtitle="Click aici pentru a accesa permisul"
                    route={`${ROUTES.DRIVER_LICENSE}/${userId}`}
                    icon={<DirectionsCarOutlinedIcon fontSize="large" />} // Custom icon
                    statusIcon={
                      userData.length > 0 &&
                      userData[0].user_inputs.some(
                        (input) =>
                          input.field_name === "driver_license" &&
                          (input.uploaded === true || input.uploaded === null)
                      ) ? (
                        userData[0].user_inputs.some(
                          (input) =>
                            input.field_name === "driver_license" &&
                            input.validated === true
                        ) ? (
                          <Tooltip
                            title="Documentul a fost încărcat și validat!"
                            arrow
                          >
                            <DoneOutlineOutlinedIcon fontSize="large" />
                          </Tooltip>
                        ) : userData[0].user_inputs.some(
                            (input) =>
                              input.field_name === "driver_license" &&
                              input.validated === false &&
                              input.validation_error
                          ) ? (
                          <Tooltip title="Documentul a fost refuzat!" arrow>
                            <DangerousOutlinedIcon fontSize="large" />
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title="Documentul a fost încărcat dar nu și validat!"
                            arrow
                          >
                            <UploadFileOutlinedIcon fontSize="large" />
                          </Tooltip>
                        )
                      ) : (
                        <Tooltip
                          title="Documentul nu a fost nici încărcat nici validat!"
                          arrow
                        >
                          <WarningOutlinedIcon fontSize="large" />
                        </Tooltip>
                      )
                    }
                  />
                </Box>
                <Box
                  gridColumn="span 4"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  flexDirection="column" // Așezare pe coloane
                  alignItems="center"
                  justifyContent="center"
                >
                  <GoToTextBox
                    title="Date personale"
                    subtitle="Click aici pentru a accesa datele personale"
                    route={`${ROUTES.PERSONAL_INFO}/${userId}`}
                    icon={<AccessibilityOutlinedIcon fontSize="large" />}
                    statusIcon={
                      getStatus(userData) === "not-uploaded" ? (
                        <Tooltip
                          title="Poza de profil nu a fost incarcata!"
                          arrow
                        >
                          <WarningOutlinedIcon fontSize="large" />
                        </Tooltip>
                      ) : getStatus(userData) === "validated" ? (
                        <Tooltip title="Date personale validate!" arrow>
                          <DoneOutlineOutlinedIcon fontSize="large" />
                        </Tooltip>
                      ) : getStatus(userData) === "error" ? (
                        <Tooltip
                          title="Eroare la validarea documentului!"
                          arrow
                        >
                          <DangerousOutlinedIcon fontSize="large" />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="Datele au fost incarcate dar nu si validate!"
                          arrow
                        >
                          <UploadFileOutlinedIcon fontSize="large" />
                        </Tooltip>
                      )
                    }
                  />
                </Box>
              </Box>
            </Box>
          )}
        </UserDataProvider>
      )}
    </IdCardDataFetcher>
  );
};

export default Onboarding;
