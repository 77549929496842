import { Box } from "@mui/material";

const ImageBox = ({ imageUrl }) => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflow="hidden" // Add overflow hidden to prevent overflowing
    >
      <img
        src={imageUrl}
        alt="no"
        style={{ maxWidth: "100%", height: "100%" }}
      />
    </Box>
  );
};

export default ImageBox;
