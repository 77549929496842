import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";

const PaymentProofCertifiedCopyFetcher = ({ userId, children }) => {
  const [paymentProofData, setPaymentProofData] = useState(null);
  const [paymentProofError, setError] = useState(null);

  useEffect(() => {
    const fetchPaymentProofData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.PAYMENT_PROOF}?id=${userId}&scope=certified_copy`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if response contains data or error
        if (response.data && response.data.data) {
          setPaymentProofData(response.data.data);
          setError(null);
        } else if (response.data && response.data.error) {
          setError(response.data.error.detail);
          setPaymentProofData(null);
        }
      } catch (error) {
        console.error(
          "Error fetching payment proof certified copy data:",
          error
        );
        setError("An error occurred while fetching payment proof data.");
        setPaymentProofData(null);
      }
    };

    fetchPaymentProofData();
  }, [userId]);

  return children(paymentProofData, paymentProofError);
};

export default PaymentProofCertifiedCopyFetcher;
