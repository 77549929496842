import { Box, Typography, useTheme, Tooltip } from "@mui/material";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import { tokens } from "../theme";

const Subscription = ({ status }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Typography sx={{ color: colors.orangeAccent[400], fontSize: "26px" }}>
        Subscription Status:
      </Typography>
      {/* Render different icons based on the status */}
      {status === "active" ? (
        <Tooltip title="Active">
          <GppGoodOutlinedIcon
            sx={{
              color: colors.orangeAccent[400],
              fontSize: "26px",
              marginLeft: "10px",
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Inactive">
          <ErrorOutlinedIcon
            sx={{
              color: colors.orangeAccent[400],
              fontSize: "26px",
              marginLeft: "10px",
            }}
          />
        </Tooltip>
      )}
    </Box>
  );
};

export default Subscription;
