import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import { tokens } from "../theme";
import { useTheme } from "@emotion/react";

const StatisticReportTable = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant="h5" color={colors.orangeAccent[400]}>
                Start Date
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {new Date(data.time_period_start).toLocaleDateString()}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h5" color={colors.orangeAccent[400]}>
                End Date
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {new Date(data.time_period_end).toLocaleDateString()}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h5" color={colors.orangeAccent[400]}>
                Subscription Fee
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>{data.subscription_fee.toFixed(2)}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h5"></Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5" color={colors.orangeAccent[400]}>
                Uber
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5" color={colors.orangeAccent[400]}>
                Bolt
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h7">Moneda</Typography>
            </TableCell>
            <TableCell>
              <Typography>{data.uber.currency}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{data.bolt.currency}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h6"></Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5" color={colors.orangeAccent[400]}>
                Castiguri
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6"></Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color={colors.orangeAccent[400]}>
                Castiguri brute
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {data.uber.income.gross_earnings.toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {data.bolt.income.gross_earnings.toFixed(2)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">Castiguri cu card</Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">
                {data.uber.income.card_earnings.toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">
                {data.bolt.income.card_earnings.toFixed(2)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">Castiguri cash</Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">
                {data.uber.income.cash_earnings.toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">
                {data.bolt.income.cash_earnings.toFixed(2)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color={colors.orangeAccent[400]}>
                Castiguri totale din curse
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>{data.uber.income.fare.toFixed(2)}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{data.bolt.income.fare.toFixed(2)}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">Bonus</Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">
                {data.uber.income.bonus_amount.toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">
                {data.bolt.income.bonus_amount.toFixed(2)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">Taxa anulare</Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">
                {data.uber.income.cancelling_fee.toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">
                {data.bolt.income.cancelling_fee.toFixed(2)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h7">Bacsis</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h7">
                {data.uber.income.tips.toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h7">
                {data.bolt.income.tips.toFixed(2)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>
              <Typography color={colors.orangeAccent[400]}>
                Castig net
              </Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography color={colors.orangeAccent[400]}>
                {data.uber.income.net_amount.toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography color={colors.orangeAccent[400]}>
                {data.bolt.income.net_amount.toFixed(2)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h5"></Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5" color={colors.orangeAccent[400]}>
                Cheltuieli
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                variant="h5"
                color={colors.orangeAccent[400]}
              ></Typography>
            </TableCell>
          </TableRow>{" "}
          <TableRow>
            <TableCell>
              <Typography variant="h6"></Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5" color={colors.orangeAccent[400]}>
                Castiguri
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6"></Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h7">Taxa platforma</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h7">
                {data.uber.expense.platform_fee.toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h7">
                {data.bolt.expense.platform_fee.toFixed(2)}
              </Typography>
            </TableCell>
          </TableRow>{" "}
          <TableRow>
            <TableCell>
              <Typography variant="h5" color={colors.orangeAccent[400]}>
                Profit
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5" color={colors.orangeAccent[400]}>
                {data.uber.income.profit.toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5" color={colors.orangeAccent[400]}>
                {" "}
                {data.bolt.income.profit.toFixed(2)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StatisticReportTable;
