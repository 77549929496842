import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "../api/axios";

const DriverDetailsTest = () => {
  const { userId } = useParams();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axios.get(
          `/user?id=a3309ed1-29e7-4355-85cf-cf929b91cc6e`, // Use the userId from useParams
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        console.log(response.data);
        isMounted && setUsers(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [userId]); // Include userId in the dependency array

  return (
    <div>
      <h2>User Details</h2>
      {users &&
        users.map((user) => (
          <div key={user.id}>
            <p>User ID: {user.id}</p>
            <p>Permission: {user.user_permission}</p>
            <p>Email: {user.email}</p>
            <p>Phone: {user.phone}</p>
            {/* Render other user details as needed */}
          </div>
        ))}
    </div>
  );
};

export default DriverDetailsTest;
