import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";
import { formatDateToYYYYMMDD } from "../helpers/dateUtils";

const SingleExpenseFetcher = ({ userId, children, expenseId }) => {
  const [singleExpenseData, setSingleExpenseData] = useState(null);
  const [singleExpenseError, setError] = useState(null);

  useEffect(() => {
    const fetchSingleExpenseData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.EXPENSE}/${userId}?expense_id=${expenseId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data && response.data.data) {
          const data = response.data.data;
          const formattedData = Array.isArray(data)
            ? data.length > 0
              ? data[0]
              : null
            : data;

          if (formattedData) {
            if (formattedData.issue_date) {
              formattedData.issue_date = formatDateToYYYYMMDD(
                formattedData.issue_date
              );
            }
            if (formattedData.files && formattedData.files.length > 0) {
              formattedData.file = formattedData.files[0].file;
            }
          }

          setSingleExpenseData(formattedData);
          setError(null);
        } else if (response.data && response.data.error) {
          setError(response.data.error);
          setSingleExpenseData(null);
        }
      } catch (error) {
        console.error("Error fetching single expense data:", error);
        setError("An error occurred while fetching single expense data.");
        setSingleExpenseData(null);
      }
    };

    fetchSingleExpenseData();
  }, [userId]);

  return children(singleExpenseData, singleExpenseError);
};

export default SingleExpenseFetcher;
