import { Box, Typography, useTheme, Stack } from "@mui/material";
import { tokens } from "../theme";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const GoToTextBox = ({
  route,
  title,
  subtitle,
  icon,
  statusIcon,
  status,
  sourceFlow,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let statusTextInactive = "Aproba capitolele anterioare pentru a avansa";

  if (sourceFlow === "expenses") {
    statusTextInactive = "Nu mai sunt facturi neaprobate!";
  }

  if (sourceFlow === "leasing") {
    statusTextInactive = "Nu are contract leasing!";
  }

  if (sourceFlow === "withoutPfa") {
    statusTextInactive = "Soferul are deja PFA!";
  }

  if (sourceFlow === "arrAuthorization") {
    statusTextInactive = "Soferul are deja autorizatie PFA!";
  }

  if (sourceFlow === "carContract") {
    statusTextInactive = "Soferul este detinut de PFA";
  }

  if (sourceFlow === "certifiedCopy") {
    statusTextInactive = "Soferul are deja copie conforma!";
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    if (status !== "inactive") {
      navigate(route);
    }
  };

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflow="hidden" // Add overflow hidden to prevent overflowing
      borderRadius="8px" // Rounded corners
      boxShadow={
        isHovered && status !== "inactive"
          ? "0px 0px 10px 5px rgba(0,0,0,0.3)"
          : "none"
      } // Add shadow on hover if status is not inactive
      cursor={status !== "inactive" ? "pointer" : "default"} // Change cursor based on status
      transition="box-shadow 0.3s" // Smooth transition for shadow
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`GoToTextBox ${status === "inactive" ? "inactive" : ""}`} // Add custom class name for inactive status
      style={{
        backgroundColor:
          status === "inactive" ? colors.grey[300] : colors.primary[400], // Use grey background for inactive status
        color:
          status === "inactive" ? colors.grey[500] : colors.orangeAccent[100], // Use grey color for text for inactive status
      }}
    >
      <Stack direction="column" alignItems="center" spacing={1}>
        {icon}
        <Box textAlign="center" p="10px">
          <Typography variant="h5" fontWeight="bold" mb={1} fontSize="1.2rem">
            {title}
          </Typography>
          <Typography variant="subtitle1" fontSize="1rem">
            {status === "inactive" ? statusTextInactive : subtitle}
          </Typography>
          {statusIcon && (
            <Box mt={1} display="flex" justifyContent="center">
              {statusIcon}
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default GoToTextBox;
