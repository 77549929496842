// ExpenseFetcher.js
import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";

const ExpenseFetcher = ({ children, userId }) => {
  const [expensesData, setExpensesData] = useState([]);
  const [localError, setLocalError] = useState("");
  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const token = localStorage.getItem("token");
        let url = EXTERNAL_ROUTES.USER;

        // Add userId to the URL if provided
        if (userId) {
          url += `?id=${userId}`;
        }

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(response);
        console.log("neaprob");
        const userData = response.data.data[0] || {};
        const userInputs = userData.user_inputs || [];

        const expenses = userInputs
          .filter(
            (input) => input.field_name.startsWith("expense") && input.validated
          ) // Filter expenses with validated set to false
          .map((expense) => {
            const fieldParts = expense.field_name.split("_");
            return {
              id: fieldParts.slice(2).join("_"), // Extract ID from field name
              type: fieldParts[1], // Extract the second part for the type
              created_at: new Date(expense.created_at)
                .toISOString()
                .split("T")[0], // Format date to YYYY-MM-DD
            };
          });
        console.log(expenses);
        setExpensesData(expenses);
      } catch (error) {
        setLocalError("Error fetching user data. Please try again.");
        console.error("Error fetching user data:", error);
      }
    };

    fetchExpenses();
  }, [userId]);

  return children(expensesData, localError);
};

export default ExpenseFetcher;
