import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";

const BankAccountStatementNewestFetcher = ({ userId, children }) => {
  const [bankAccountStatementData, setBankAccountStatementData] =
    useState(null);
  const [bankError, setError] = useState(null);
  const [latestStatementId, setLatestStatementId] = useState(null);
  const [latestStatementData, setLatestStatementData] = useState(null);

  useEffect(() => {
    const fetchBankAccountStatementData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.BANK_ACCOUNT_STATEMENT}/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data && response.data.data) {
          const statementData = response.data.data;
          setBankAccountStatementData(statementData);

          if (statementData && statementData.length > 0) {
            const latestStatement = statementData[statementData.length - 1];
            setLatestStatementId(latestStatement.id);
          }

          setError(null);
        } else if (response.data && response.data.error) {
          setError(response.data.error.detail);
          setBankAccountStatementData(null);
        }
      } catch (error) {
        console.error("Error fetching bank account data:", error);
        setError("An error occurred while fetching bank account data.");
        setBankAccountStatementData(null);
      }
    };

    fetchBankAccountStatementData();
  }, [userId]); // Add userId to the dependency array

  useEffect(() => {
    const fetchLatestBankAccountStatement = async () => {
      if (latestStatementId) {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            `${EXTERNAL_ROUTES.BANK_ACCOUNT_STATEMENT}/${userId}?bank_account_statement_id=${latestStatementId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const latest = response?.data?.data[0];
          setLatestStatementData(latest);
        } catch (error) {
          console.error(
            "Error fetching latest bank account statement data:",
            error
          );
        }
      }
    };

    fetchLatestBankAccountStatement();
  }, [latestStatementId, userId]); // Add userId and latestStatementId to the dependency array

  return children(latestStatementData, bankError);
};

export default BankAccountStatementNewestFetcher;
