import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";

const CarIdentityCardFetcher = ({ userId, children }) => {
  const [carIdentityCardData, setCarIdentityCardData] = useState(null);
  const [carIdentityCardError, setError] = useState(null);

  useEffect(() => {
    const fetchCardIdentityCarData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.CAR_IDENTITY_CARD}?id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if response contains data or error
        if (response.data && response.data.data) {
          setCarIdentityCardData(response.data.data);
          setError(null);
        } else if (response.data && response.data.error) {
          setError(response.data.error.detail);
          setCarIdentityCardData(null);
        }
      } catch (error) {
        console.error("Error fetching car identity card data:", error);
        setError("An error occurred while fetching car identity card data.");
        setCarIdentityCardData(null);
      }
    };

    fetchCardIdentityCarData();
  }, [userId]);

  return children(carIdentityCardData, carIdentityCardError);
};

export default CarIdentityCardFetcher;
