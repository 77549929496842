import {
  Alert,
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import UserDataProvider from "../../context/UserDataProvider";
import { useState } from "react";
import { ROUTES } from "../../helpers/routes";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import ConnectedDriverAccountsFetcher from "../../context/ConnectedDriverAccountsFetcher";

const DriverAccount = () => {
  const theme = useTheme();
  const { userId } = useParams();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [error, setError] = useState(""); // State for storing error message

  const handleGoBack = (userId) => {
    navigate(`${ROUTES.DRIVER_DETAILS}/${userId}`);
  };

  return (
    <ConnectedDriverAccountsFetcher userId={userId}>
      {(connectedDriverAccountsData) => (
        <UserDataProvider userId={userId} setError={setError}>
          {(userData) => (
            <Box m="20px">
              {/* HEADER */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Header
                  title="Date cont sofer"
                  subtitle={`Detaliile cont sofer pentru user: ${userId}`}
                />
                <Box>
                  <Button
                    sx={{
                      backgroundColor: colors.primary[700],
                      color: colors.orangeAccent[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                    onClick={() => handleGoBack(userId)}
                  >
                    <ArrowBackOutlinedIcon sx={{ mr: "10px" }} />
                    Back
                  </Button>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {/* Display error message */}
                {error && (
                  <Alert severity="error">Error message: {error}</Alert>
                )}{" "}
              </Box>
              <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
              >
                {/* Verificarea dacă există date valide sau eroare */}
                {connectedDriverAccountsData ? (
                  connectedDriverAccountsData.map((account, index) => (
                    <Box
                      key={index}
                      gridColumn="span 6"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={11}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography
                              sx={{
                                color: colors.orangeAccent[400],
                                fontSize: "26px",
                              }}
                            >
                              {account.name} {/* Numele contului */}
                            </Typography>
                            {/* Verificarea stării contului și afișarea iconiței corespunzătoare */}
                            {account.connected ? (
                              <Tooltip title="Activ">
                                <GppGoodOutlinedIcon
                                  sx={{
                                    color: colors.orangeAccent[400],
                                    fontSize: "26px",
                                    marginLeft: "10px",
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Inactiv">
                                <ErrorOutlinedIcon
                                  sx={{
                                    color: colors.orangeAccent[400],
                                    fontSize: "26px",
                                    marginLeft: "10px",
                                  }}
                                />
                              </Tooltip>
                            )}
                          </Box>
                          <Box height="20px" /> {/* Spațiu între rânduri */}
                        </Grid>
                      </Grid>
                    </Box>
                  ))
                ) : (
                  /* Afisare ca inactiv daca nu exista date valide */
                  <>
                    <Box
                      gridColumn="span 6"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={11}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography
                              sx={{
                                color: colors.orangeAccent[400],
                                fontSize: "26px",
                              }}
                            >
                              Uber Fleet {/* Numele contului */}
                            </Typography>
                            {/* Iconita pentru inactiv */}
                            <Tooltip title="Inactiv">
                              <ErrorOutlinedIcon
                                sx={{
                                  color: colors.orangeAccent[400],
                                  fontSize: "26px",
                                  marginLeft: "10px",
                                }}
                              />
                            </Tooltip>
                          </Box>
                          <Box height="20px" /> {/* Spațiu între rânduri */}
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      gridColumn="span 6"
                      gridRow="span 2"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={11}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography
                              sx={{
                                color: colors.orangeAccent[400],
                                fontSize: "26px",
                              }}
                            >
                              Bolt Fleet {/* Numele contului */}
                            </Typography>
                            {/* Iconita pentru inactiv */}
                            <Tooltip title="Inactiv">
                              <ErrorOutlinedIcon
                                sx={{
                                  color: colors.orangeAccent[400],
                                  fontSize: "26px",
                                  marginLeft: "10px",
                                }}
                              />
                            </Tooltip>
                          </Box>
                          <Box height="20px" /> {/* Spațiu între rânduri */}
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          )}
        </UserDataProvider>
      )}
    </ConnectedDriverAccountsFetcher>
  );
};

export default DriverAccount;
