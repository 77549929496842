import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import OnDeviceTrainingOutlinedIcon from "@mui/icons-material/OnDeviceTrainingOutlined";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import DescriptionOutlined from "@mui/icons-material/DescriptionOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { ROUTES } from "../../helpers/routes";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import ProgressCircle from "../../components/ProgressCircle";
import { useParams } from "react-router-dom";
import InfoBox from "../../components/InfoBox";
import Subscription from "../../components/Subscription";
import ImageBox from "../../components/ImageBox";
import GoToTextBox from "../../components/GoToTextBox";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import ProfilePictureLoader from "../../context/ProfilePictureLoader";
import IdCardDataFetcher from "../../context/IdCardDataFetcher";
import UserDataProvider from "../../context/UserDataProvider";
import StatisticReportFetcher from "../../context/StatisticReportFetcher";
import StatisticReportTable from "../../components/StatisticReportTable";
import TaxiAlertOutlinedIcon from "@mui/icons-material/TaxiAlertOutlined";
import AccountingDataFetcher from "../../context/AccountingDataFetcher";
import AccountingReportTable from "../../components/AccountingReportTable";
import ExpenseFetcher from "../../context/ExpenseFetcher";
import {
  getOnboardingStatus,
  getAtestatChapterStatus,
  getPfaChapterStatus,
  getArrChapterStatus,
  getExpensesStatus,
  getCertificateStatus,
  hasWaitingExpenses,
  getPfaStatus,
  getBankingStatus,
} from "../../helpers/statusCalculator";

const DriverDetails = () => {
  const theme = useTheme();
  const { userId } = useParams();
  const colors = tokens(theme.palette.mode);

  return (
    <IdCardDataFetcher userId={userId}>
      {(idCardData) => (
        <UserDataProvider userId={userId}>
          {(userData) => (
            <StatisticReportFetcher userId={userId}>
              {(statisticReportData) => (
                <AccountingDataFetcher userId={userId}>
                  {(accountingData) => (
                    <Box m="20px">
                      {/* HEADER */}
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Header
                          title="Dashboard sofer"
                          subtitle={`Detaliile pentru user ID: ${userId}`}
                        />
                      </Box>

                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="140px"
                        gap="20px"
                      >
                        {/* ROW 1 */}
                        <Box
                          gridColumn="span 3"
                          backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <InfoBox
                            title="Full Name"
                            subtitle={
                              idCardData?.last_name && idCardData?.first_name
                                ? `${idCardData.last_name} ${idCardData.first_name}`
                                : "Datele nu au fost gasite pe document!"
                            }
                            icon={
                              <Person2OutlinedIcon
                                sx={{
                                  color: colors.orangeAccent[400],
                                  fontSize: "26px",
                                }}
                              />
                            }
                          />
                        </Box>
                        <Box
                          gridColumn="span 3"
                          backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <InfoBox
                            title="Email"
                            subtitle={
                              userData.length > 0 && userData[0].email
                                ? userData[0].email
                                : "Email inexistent"
                            }
                            icon={
                              <MailOutlinedIcon
                                sx={{
                                  color: colors.orangeAccent[400],
                                  fontSize: "26px",
                                }}
                              />
                            }
                          />
                        </Box>
                        <Box
                          gridColumn="span 3"
                          backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <InfoBox
                            title="Phone number"
                            subtitle={
                              userData.length > 0 && userData[0].phone
                                ? userData[0].phone
                                : "Nr Telefon inexistent"
                            }
                            icon={
                              <LocalPhoneOutlinedIcon
                                sx={{
                                  color: colors.orangeAccent[400],
                                  fontSize: "26px",
                                }}
                              />
                            }
                          />
                        </Box>
                        <Box
                          gridColumn="span 3"
                          backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Subscription status="inactive" />
                        </Box>
                        {/* ROW 2 */}
                        <Box
                          gridColumn="span 3"
                          gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <InfoBox
                            title="Oras de lucru"
                            subtitle={
                              userData.length > 0 && userData[0].working_city
                                ? userData[0].working_city
                                : "Oras de lucru inexistent"
                            }
                            icon={
                              <ApartmentOutlinedIcon
                                sx={{
                                  color: colors.orangeAccent[400],
                                  fontSize: "26px",
                                }}
                              />
                            }
                          />
                        </Box>
                        <Box
                          gridColumn="span 3"
                          gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <InfoBox
                            title="Status sofer"
                            subtitle={
                              userData.length > 0 &&
                              userData[0].onboarding_completed_acceptance
                                ? "Aprobat"
                                : "In curs de aprobare"
                            }
                            icon={
                              <OnDeviceTrainingOutlinedIcon
                                sx={{
                                  color: colors.orangeAccent[400],
                                  fontSize: "26px",
                                }}
                              />
                            }
                          />
                        </Box>
                        <Box
                          gridColumn="span 3"
                          gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <InfoBox
                            title="Address"
                            subtitle={`${idCardData?.address} ${idCardData?.city}`}
                            icon={
                              <HomeOutlinedIcon
                                sx={{
                                  color: colors.orangeAccent[400],
                                  fontSize: "26px",
                                }}
                              />
                            }
                          />
                        </Box>
                        <Box
                          gridColumn="span 3"
                          gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          padding="10px"
                        >
                          <ProfilePictureLoader userId={userId}>
                            {(profilePicture) =>
                              profilePicture ? (
                                <ImageBox imageUrl={profilePicture} />
                              ) : (
                                <Typography
                                  variant="h5"
                                  color={colors.orangeAccent[500]}
                                  sx={{ mt: "15px" }}
                                >
                                  No picture
                                </Typography>
                              )
                            }
                          </ProfilePictureLoader>
                        </Box>
                        <Box
                          gridColumn="span 12"
                          gridRow="span 1"
                          backgroundColor={colors.primary[400]}
                          p="30px"
                        >
                          {" "}
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Header
                              title="Documente"
                              subtitle={`Documentele necesare pentru aprobare manuala sofer!`}
                            />
                          </Box>
                        </Box>
                        <Box
                          gridColumn="span 4"
                          gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                          p="30px"
                        >
                          <GoToTextBox
                            title="Inregistrare"
                            subtitle="Click pentru a verifica datele de inregistrare!"
                            route={`${ROUTES.ONBOARDING}/${userId}`}
                            icon={
                              <AppRegistrationOutlinedIcon fontSize="large" />
                            } // Custom icon
                            statusIcon={
                              getOnboardingStatus(userData) === "active" ? (
                                <Tooltip title="Onboarding finalizat!" arrow>
                                  <DoneOutlineOutlinedIcon fontSize="large" />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title="Onboardingul nu a fost aprobat integral!"
                                  arrow
                                >
                                  <WarningOutlinedIcon fontSize="large" />
                                </Tooltip>
                              )
                            }
                          />
                        </Box>
                        <Box
                          gridColumn="span 4"
                          gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                          p="30px"
                        >
                          <GoToTextBox
                            title="Atestat"
                            subtitle="Click pentru a verifica zona Atestat!"
                            route={`${ROUTES.CERTIFICATE}/${userId}`}
                            icon={<DescriptionOutlined fontSize="large" />} // Custom icon
                            statusIcon={
                              getCertificateStatus(userData) === "active" ? (
                                <Tooltip title="Atestat finalizat!" arrow>
                                  <DoneOutlineOutlinedIcon fontSize="large" />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title="Capitolul atestat nu a fost aprobat integral!"
                                  arrow
                                >
                                  <WarningOutlinedIcon fontSize="large" />
                                </Tooltip>
                              )
                            }
                            status={getOnboardingStatus(userData)}
                          />
                        </Box>
                        <Box
                          gridColumn="span 4"
                          gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                          p="30px"
                        >
                          <GoToTextBox
                            title="PFA"
                            subtitle="Click pentru a verifica zona PFA!"
                            route={`${ROUTES.PFA}/${userId}`}
                            icon={<DescriptionOutlined fontSize="large" />} // Custom icon
                            statusIcon={
                              getPfaStatus(userData) === "active" ? (
                                <Tooltip title="Capitol PFA finalizat!" arrow>
                                  <DoneOutlineOutlinedIcon fontSize="large" />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title="Capitolul PFA nu a fost aprobat integral!"
                                  arrow
                                >
                                  <WarningOutlinedIcon fontSize="large" />
                                </Tooltip>
                              )
                            }
                            status={getAtestatChapterStatus(userData)}
                          />
                        </Box>
                        <Box
                          gridColumn="span 4"
                          gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                          p="30px"
                        >
                          <GoToTextBox
                            title="ARR"
                            subtitle="Click pentru a verifica zona ARR!"
                            route={`${ROUTES.ARR}/${userId}`}
                            icon={<DescriptionOutlined fontSize="large" />} // Custom icon
                            statusIcon={
                              getArrChapterStatus(userData) === "active" ? (
                                <Tooltip title="Capitol ARR finalizat!" arrow>
                                  <DoneOutlineOutlinedIcon fontSize="large" />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title="Capitolul ARR nu a fost aprobat integral!"
                                  arrow
                                >
                                  <WarningOutlinedIcon fontSize="large" />
                                </Tooltip>
                              )
                            }
                            status={getPfaChapterStatus(userData)}
                          />
                        </Box>
                        <Box
                          gridColumn="span 4"
                          gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                          p="30px"
                        >
                          <GoToTextBox
                            title="Date financiare"
                            subtitle="Click pentru a verifica zona date financiare!"
                            route={`${ROUTES.BANKING_STATS}/${userId}`}
                            icon={
                              <AccountBalanceOutlinedIcon fontSize="large" />
                            } // Custom icon
                            statusIcon={
                              getBankingStatus(userData) === "active" ? (
                                <Tooltip
                                  title="Date financiare - finalizat!"
                                  arrow
                                >
                                  <DoneOutlineOutlinedIcon fontSize="large" />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title="Capitolul date financiare nu a fost aprobat integral!"
                                  arrow
                                >
                                  <WarningOutlinedIcon fontSize="large" />
                                </Tooltip>
                              )
                            }
                            status={getArrChapterStatus(userData)}
                          />
                        </Box>
                        <Box
                          gridColumn="span 4"
                          gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                          p="30px"
                        >
                          <GoToTextBox
                            title="Conturi Bolt si Uber"
                            subtitle="Click pentru a verifica zona cont Bolt si Uber!"
                            route={`${ROUTES.PFA_ACCOUNT}/${userId}`}
                            icon={<TaxiAlertOutlinedIcon fontSize="large" />} // Custom icon
                            statusIcon={
                              <WarningOutlinedIcon fontSize="large" />
                            }
                            status={getArrChapterStatus(userData)}
                          />
                        </Box>
                        <Box
                          gridColumn="span 4"
                          gridRow="span 1"
                          backgroundColor={colors.primary[400]}
                          p="30px"
                        >
                          {" "}
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Header
                              title="Statistici"
                              subtitle={`Statistici si rapoarte sofer!`}
                            />
                          </Box>
                        </Box>{" "}
                        <Box
                          gridColumn="span 4"
                          gridRow="span 1"
                          backgroundColor={colors.primary[400]}
                          p="30px"
                        >
                          {" "}
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Header
                              title="Contabilitate sofer"
                              subtitle={`Contabilitate`}
                            />
                          </Box>
                        </Box>{" "}
                        <Box
                          gridColumn="span 4"
                          gridRow="span 1"
                          backgroundColor={colors.primary[400]}
                          p="30px"
                        >
                          {" "}
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Header
                              title="Facturi sofer"
                              subtitle={`Facturi`}
                            />
                          </Box>
                        </Box>
                        <Box
                          gridColumn="span 4"
                          gridRow="span 9"
                          backgroundColor={colors.primary[400]}
                          p="30px"
                        >
                          <Typography variant="h5" fontWeight="600">
                            Statistici financiare
                          </Typography>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mt="25px"
                          >
                            <ProgressCircle
                              progress={
                                (statisticReportData?.total?.total_profit ||
                                  0) /
                                ((statisticReportData?.total?.total_profit ||
                                  0) +
                                  (statisticReportData?.total?.total_expenses ||
                                    0.00001))
                              }
                              size="125"
                            />
                            <Typography
                              variant="h5"
                              color={colors.grey[500]}
                              sx={{ mt: "15px" }}
                            >
                              Venit brut:{" "}
                              {statisticReportData?.total?.total_gross_earnings.toFixed(
                                2
                              ) || 0}
                            </Typography>
                            <Typography
                              variant="h5"
                              color={colors.orangeAccent[500]}
                              sx={{ mt: "15px" }}
                            >
                              Profit:{" "}
                              {statisticReportData?.total?.total_profit.toFixed(
                                2
                              ) || 0}
                            </Typography>
                            <Typography
                              variant="h5"
                              color={colors.blueAccent[500]}
                              sx={{ mt: "15px" }}
                            >
                              Cheltuieli:{" "}
                              {statisticReportData?.total?.total_expenses.toFixed(
                                2
                              ) || 0}
                            </Typography>
                            <Box sx={{ m: 3 }}></Box>
                            <Typography>
                              {statisticReportData ? (
                                <StatisticReportTable
                                  data={statisticReportData}
                                />
                              ) : (
                                "Datele nu sunt disponibile! Reîncercați!"
                              )}
                            </Typography>
                          </Box>
                        </Box>{" "}
                        <Box
                          gridColumn="span 4"
                          gridRow="span 7"
                          backgroundColor={colors.primary[400]}
                          p="30px"
                        >
                          <Typography variant="h5" fontWeight="600">
                            Contabilitate
                          </Typography>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mt="25px"
                          >
                            <ProgressCircle
                              progress={
                                (accountingData?.net_amount || 0) /
                                (accountingData?.gross_earnings || 0.00000001)
                              }
                              size="125"
                            />
                            <Typography
                              variant="h5"
                              color={colors.grey[500]}
                              sx={{ mt: "15px" }}
                            >
                              Venit brut:{" "}
                              {accountingData?.gross_earnings.toFixed(2) || 0}
                            </Typography>
                            <Typography
                              variant="h5"
                              color={colors.orangeAccent[500]}
                              sx={{ mt: "15px" }}
                            >
                              Profit dupa taxe:{" "}
                              {accountingData?.net_amount.toFixed(2) || 0}
                            </Typography>
                            <Typography
                              variant="h5"
                              color={colors.blueAccent[500]}
                              sx={{ mt: "15px" }}
                            >
                              Taxe:{" "}
                              {(
                                accountingData?.gross_earnings -
                                accountingData?.net_amount
                              ).toFixed(2) || 0}
                            </Typography>
                            <Box sx={{ m: 3 }}></Box>
                            <Typography>
                              {accountingData ? (
                                <AccountingReportTable data={accountingData} />
                              ) : (
                                "Datele nu sunt disponibile! Reîncercați!"
                              )}
                            </Typography>
                          </Box>
                        </Box>
                        {/* ROW 3 */}
                        <Box
                          gridColumn="span 4"
                          gridRow="span 4"
                          backgroundColor={colors.primary[400]}
                          overflow="auto"
                        >
                          <Box
                            gridColumn="span 4"
                            gridRow="span 1"
                            backgroundColor={colors.primary[400]}
                            p="30px"
                          >
                            <GoToTextBox
                              title="Facturi neaprobate"
                              subtitle="Click pentru a verifica facturile neaprobate!"
                              route={`${ROUTES.EXPENSE_NOT_APPROVED_TABLE}/${userId}`}
                              sourceFlow="expenses"
                              icon={
                                <AssignmentLateOutlinedIcon fontSize="large" />
                              } // Custom icon
                              statusIcon={
                                hasWaitingExpenses(userData) === "false" ? (
                                  <Tooltip
                                    title="Facturi aprobate integral!"
                                    arrow
                                  >
                                    <DoneOutlineOutlinedIcon fontSize="large" />
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="Mai sunt facturi neaprobate!"
                                    arrow
                                  >
                                    <WarningOutlinedIcon fontSize="large" />
                                  </Tooltip>
                                )
                              }
                              status={getExpensesStatus(userData)}
                            />
                          </Box>
                          <Box
                            gridColumn="span 4"
                            gridRow="span 1"
                            backgroundColor={colors.primary[400]}
                            p="30px"
                          >
                            <GoToTextBox
                              title="Facturi aprobate"
                              subtitle="Click pentru a vedea facturile aprobate!"
                              route={`${ROUTES.EXPENSE_TABLE}/${userId}`}
                              icon={<DescriptionOutlined fontSize="large" />} // Custom icon
                            />
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            colors={colors.grey[100]}
                            p="15px"
                          >
                            <Typography
                              color={colors.grey[100]}
                              variant="h5"
                              fontWeight="600"
                            >
                              Facturi recente
                            </Typography>
                          </Box>
                          <ExpenseFetcher userId={userId}>
                            {(expenses, error) => {
                              if (error) {
                                return <div>Error: {error.message}</div>;
                              } else {
                                return (
                                  <>
                                    {expenses.map((transaction, i) => (
                                      <Box
                                        key={`${transaction.id}-${i}`}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        borderBottom={`4px solid ${colors.orangeAccent[200]}`}
                                        p="15px"
                                      >
                                        <Box>
                                          <Typography
                                            color={colors.orangeAccent[500]}
                                            variant="h5"
                                            fontWeight="600"
                                          >
                                            {transaction.issueDate}
                                          </Typography>{" "}
                                          <Typography
                                            color={colors.orangeAccent[500]}
                                            variant="h5"
                                            fontWeight="600"
                                          >
                                            Tip: {transaction.type}
                                          </Typography>
                                        </Box>
                                        <Box color={colors.grey[100]}>
                                          Data: {transaction.amount}
                                        </Box>
                                        <Box
                                          backgroundColor={
                                            colors.orangeAccent[500]
                                          }
                                          p="5px 10px"
                                          borderRadius="4px"
                                        >
                                          {transaction.created_at}
                                        </Box>
                                      </Box>
                                    ))}
                                  </>
                                );
                              }
                            }}
                          </ExpenseFetcher>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </AccountingDataFetcher>
              )}
            </StatisticReportFetcher>
          )}
        </UserDataProvider>
      )}
    </IdCardDataFetcher>
  );
};

export default DriverDetails;
