import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import { tokens } from "../theme";
import { useTheme } from "@emotion/react";

const AccountingReportTable = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant="h5" color={colors.orangeAccent[400]}>
                Start Date
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {new Date(data.time_period_start).toLocaleDateString()}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h5" color={colors.orangeAccent[400]}>
                End Date
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {new Date(data.time_period_end).toLocaleDateString()}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h5" color={colors.orangeAccent[400]}>
                Venit brut
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>{data.gross_earnings.toFixed(2)}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h5" color={colors.orangeAccent[400]}>
                Cheltuieli
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5">
                {data.total.expenses.toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                variant="h5"
                color={colors.orangeAccent[400]}
              ></Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">Administrative</Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">
                {" "}
                {data.expenses.administrative.toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography></Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h7">Fares</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h7">
                {" "}
                {data.expenses.fare.toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6"></Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h5" color={colors.orangeAccent[400]}>
                Taxe
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5">
                {data.total.taxes.toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                variant="h5"
                color={colors.orangeAccent[400]}
              ></Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">CAS</Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7"> {data.taxes.cas.toFixed(2)}</Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h6"></Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">CASS</Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">
                {" "}
                {data.taxes.cass.toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h6"></Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">Impozit pe venit</Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography variant="h7">
                {" "}
                {data.taxes.income_tax.toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Typography></Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h7">TVA Intracomunitar</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h7">
                {" "}
                {data.taxes.intra_community_tva.toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6"></Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h5" color={colors.orangeAccent[400]}>
                Profit dupa taxe
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5">{data.net_amount.toFixed(2)}</Typography>
            </TableCell>
            <TableCell>
              <Typography
                variant="h5"
                color={colors.orangeAccent[400]}
              ></Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AccountingReportTable;
