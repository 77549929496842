import { Box, Typography, useTheme, Stack } from "@mui/material";
import { tokens } from "../theme";
import { useState } from "react";
import { saveAs } from "file-saver";

const DownloadPfaBox = ({ title, subtitle, icon, file, name }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = async () => {
    try {
      if (file) {
        saveAs(file, name);
      } else {
        console.error("No zip data available.");
      }
    } catch (error) {
      console.error("Error downloading zip:", error);
    }
  };

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflow="hidden" // Add overflow hidden to prevent overflowing
      borderRadius="8px" // Rounded corners
      boxShadow={isHovered ? "0px 0px 10px 5px rgba(0,0,0,0.3)" : "none"}
      transition="box-shadow 0.3s" // Smooth transition for shadow
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        backgroundColor: colors.primary[400], // Use grey background for inactive status
        color: colors.orangeAccent[100], // Use grey color for text for inactive status
      }}
    >
      <Stack direction="column" alignItems="center" spacing={1}>
        {icon}
        <Box textAlign="center" p="10px">
          <Typography variant="h5" fontWeight="bold" mb={1} fontSize="1.2rem">
            {title}
          </Typography>
          <Typography variant="subtitle1" fontSize="1rem">
            {subtitle}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default DownloadPfaBox;
