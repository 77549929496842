import { Alert, Box, Button, CircularProgress, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import IdCardDataFetcher from "../../context/IdCardDataFetcher";
import UserDataProvider from "../../context/UserDataProvider";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { ROUTES } from "../../helpers/routes";
import DownloadPfaBox from "../../components/DownloadPfaBox";
import DownloadSpvTvaFolder from "../../context/DownloadSpvTva";

const SpvTvaFolder = () => {
  const theme = useTheme();
  const { userId } = useParams();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const handleGoBack = (userId) => {
    navigate(`${ROUTES.BANKING_STATS}/${userId}`);
  };

  return (
    <IdCardDataFetcher userId={userId}>
      {(idCardData) => (
        <UserDataProvider userId={userId}>
          {(userData) => (
            <Box m="20px">
              {/* HEADER */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Header
                  title="Dosar SPV TVA"
                  subtitle={`Dosar SPV TVA: ${userId}`}
                />
                <Box>
                  <Button
                    sx={{
                      backgroundColor: colors.primary[700],
                      color: colors.orangeAccent[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                    onClick={() => handleGoBack(userId)}
                  >
                    <ArrowBackOutlinedIcon sx={{ mr: "10px" }} />
                    Back
                  </Button>
                </Box>
              </Box>

              <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
              >
                <Box
                  gridColumn="span 4"
                  gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                  p="30px"
                >
                  <DownloadSpvTvaFolder userId={userId}>
                    {(spvTvaZip, errorSpvTvaZip, isLoading) => {
                      if (isLoading) {
                        return <CircularProgress color="inherit" />;
                      } else if (errorSpvTvaZip) {
                        return (
                          <Alert severity="error">
                            Error: {errorSpvTvaZip}
                          </Alert>
                        );
                      } else {
                        return (
                          <DownloadPfaBox
                            userId={userId}
                            file={spvTvaZip}
                            title="Descarca dosar Spv TVA"
                            subtitle="Click pentru a descarca dosar SPV TVA!"
                            name={`${idCardData.first_name}-${idCardData.last_name}-spv-tva.zip`}
                            icon={
                              <CloudDownloadOutlinedIcon fontSize="large" />
                            }
                          />
                        );
                      }
                    }}
                  </DownloadSpvTvaFolder>
                </Box>
              </Box>
            </Box>
          )}
        </UserDataProvider>
      )}
    </IdCardDataFetcher>
  );
};

export default SpvTvaFolder;
