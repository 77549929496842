import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";
import StatisticReportDTO from "../helpers/StatisticReportDto";

const StatisticReportFetcher = ({ userId, children }) => {
  const [statisticReportData, setStatisticReportData] = useState(null);
  const [statisticReportError, setError] = useState(null);

  useEffect(() => {
    const fetchStatisticReportData = async () => {
      try {
        const currentDate = new Date();
        const firstDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const yesterday = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - 1
        );

        const formattedFirstDayOfMonth = `${firstDayOfMonth.getFullYear()}-${(
          firstDayOfMonth.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${firstDayOfMonth
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        const formattedYesterday = `${yesterday.getFullYear()}-${(
          yesterday.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${yesterday
          .getDate()
          .toString()
          .padStart(2, "0")}`;

        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.STATISTIC_REPORT}/${userId}?start_date=${formattedFirstDayOfMonth}&end_date=${formattedYesterday}&time_period_type=daily`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Verifică dacă răspunsul conține date sau eroare
        if (response.data && response.data.data) {
          // Creează un nou obiect DTO cu datele răspunsului
          const statisticReportDTO = new StatisticReportDTO(response.data.data);
          setStatisticReportData(statisticReportDTO);
          setError(null);
        } else if (response.data && response.data.error) {
          setError(response.data.error.detail);
          setStatisticReportData(null);
        }
      } catch (error) {
        console.error("Error fetching statistic report data:", error);
        setError("An error occurred while fetching statistic report data.");
        setStatisticReportData(null);
      }
    };

    fetchStatisticReportData();
  }, [userId]);
  return children(statisticReportData, statisticReportError);
};

export default StatisticReportFetcher;
