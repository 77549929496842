import React from "react";
import "./LoginForm.css";
import { FaUser, FaLock } from "react-icons/fa";
import pick_logo from "../../pick_logo.png";
import { useRef, useState, useEffect } from "react";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";

const LOGIN_URL = "/login";

const LoginForm = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errorRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMessage, setErrMessage] = useState("");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMessage("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email: user, password: pwd }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      const accessToken = response?.data?.data?.token;
      const expirationTime = response?.data?.data?.expiresIn;
      const userId = response?.data?.data?.userId;
      const isLogged = true;

      setAuth({ user, pwd, accessToken, isLogged });
      localStorage.setItem("token", accessToken);
      localStorage.setItem("expirationTime", expirationTime);
      localStorage.setItem("userId", userId);
      console.log(accessToken);
      setUser("");
      setPwd("");
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMessage("No server response");
      } else if (err.response?.status === 400) {
        setErrMessage("Missing Username or password");
      } else if (err.response?.status === 401) {
        setErrMessage("Unauthorized");
      } else {
        setErrMessage("Login failed!");
        console.log(err.response);
      }

      errorRef.current.focus();
    }
  };
  // Check if token exists in localStorage
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Redirect to a different page or handle the logic accordingly
      navigate(from, { replace: true });
    }
  }, [from, navigate]);

  return (
    <div className="loginForm">
      <section className="wrapper">
        <form onSubmit={handleSubmit}>
          <div>
            <img src={pick_logo} alt="Logo" />
          </div>
          <p
            ref={errorRef}
            className={errMessage ? "errMsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMessage}
          </p>
          <div className="input-box">
            <input
              type="text"
              ref={userRef}
              placeholder="Username"
              onChange={(e) => setUser(e.target.value)}
              value={user}
              required
            />
            <FaUser className="icon" />
          </div>
          <div className="input-box">
            <input
              type="password"
              placeholder="Password"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
            />
            <FaLock className="icon" />
          </div>
          <div className="remember-forgot">
            <label>
              <input type="checkbox" />
              Remember me
            </label>
            <a href="#dummy">Forgot password?</a>
          </div>
          <button type="submit">Login</button>
          <div className="register-link">
            <p>
              Don't have an account?{" "}
              <a href="#dummy">
                Find out how to <b>Register</b>
              </a>
            </p>
          </div>
        </form>
      </section>
    </div>
  );
};

export default LoginForm;
