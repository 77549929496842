import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import { useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { ROUTES } from "../../helpers/routes";
import ExpensesNotApprovedFetcher from "../../context/ExpensesNotApprovedFetcher";

const NotApprovedExpenses = () => {
  const { userId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);

  const handleExpenseClick = (id) => {
    navigate(`${ROUTES.EXPENSE}/${id}?userId=${userId}`);
  };

  const handleGoBack = (userId) => {
    navigate(`${ROUTES.DRIVER_DETAILS}/${userId}`);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "created_at", headerName: "Data", flex: 1 },
    {
      field: "check_expense",
      headerName: "Aproba factura",
      flex: 1,
      renderCell: ({ row }) => (
        <Box
          width="80%"
          m="0 auto"
          p="5px"
          display="flex"
          justifyContent="center"
          backgroundColor={colors.redAccent[800]}
          borderRadius="4px"
          onClick={() => handleExpenseClick(row.id)}
        >
          <SpaceDashboardOutlinedIcon />
          <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
            Aproba factura
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="Facturi neaprobate"
        subtitle="Management facturi neaprobate"
      />{" "}
      <Box>
        <Button
          sx={{
            backgroundColor: colors.primary[700],
            color: colors.orangeAccent[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
          onClick={() => handleGoBack(userId)}
        >
          <ArrowBackOutlinedIcon sx={{ mr: "10px" }} />
          Back
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .name-column--cell": { color: colors.orangeAccent[900] },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.orangeAccent[300],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.orangeAccent[300],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.orangeAccent[200]} !important`,
          },
        }}
      >
        <ExpensesNotApprovedFetcher userId={userId}>
          {(expenseData, error) => {
            if (error) {
              return <Typography color="error">{error}</Typography>;
            }
            if (expenseData.length === 0) {
              return (
                <Typography color="textSecondary">
                  No expenses found.
                </Typography>
              );
            }
            return <DataGrid rows={expenseData} columns={columns} />;
          }}
        </ExpensesNotApprovedFetcher>
      </Box>
    </Box>
  );
};

export default NotApprovedExpenses;
