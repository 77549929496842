import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";

const DownloadSpvCnpFolder = ({ userId, children }) => {
  const [pfaCnpZip, setSpvCnpZip] = useState(null);
  const [spvCnpError, setError] = useState(null);
  const [isSpvCnpLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.FOLDER}?id=${userId}&scope=spv_cnp`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          }
        );

        // Check if response contains data or error
        if (response.data) {
          setSpvCnpZip(response.data);
          setError(null);
        } else if (response.error) {
          console.log(response.error);
          setError(response.error.detail);
          setSpvCnpZip(null);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching zip spv_cui data:", error);
        setError("An error occurred while downloading zip data.");
        setSpvCnpZip(null);
        setIsLoading(false);
      }
    };

    fetchFile();
  }, [userId]);

  return children(pfaCnpZip, spvCnpError, isSpvCnpLoading);
};

export default DownloadSpvCnpFolder;
