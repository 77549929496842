import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";

const PfaConfirmationStatementFetcher = ({ userId, children }) => {
  const [confirmationStatementData, setConfirmationStatementData] =
    useState(null);
  const [confirmationStatementError, setError] = useState(null);

  useEffect(() => {
    const fetchConfirmationStatementData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.PFA_CONFIRMATION_STATEMENT}?id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if response contains data or error
        if (response.data && response.data.data) {
          setConfirmationStatementData(response.data.data);
          setError(null);
        } else if (response.data && response.data.error) {
          setError(response.data.error.detail);
          setConfirmationStatementData(null);
        }
      } catch (error) {
        console.error("Error fetching confirmation statement data:", error);
        setError(
          "An error occurred while fetching confirmation statement data."
        );
        setConfirmationStatementData(null);
      }
    };

    fetchConfirmationStatementData();
  }, [userId]);

  return children(confirmationStatementData, confirmationStatementError);
};

export default PfaConfirmationStatementFetcher;
