import { useEffect, useState } from "react";
import axios from "../api/axios";
import { EXTERNAL_ROUTES } from "../helpers/routes";

const CompanyBankAccountFetcher = ({ userId, children }) => {
  const [companyBankAccountData, setCompanyBankAccountData] = useState(null);
  const [companyBankError, setError] = useState(null);

  useEffect(() => {
    const fetchCompanyBankAccountData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${EXTERNAL_ROUTES.COMPANY_BANK_ACCOUNT}?id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if response contains data or error
        if (response.data && response.data.data) {
          setCompanyBankAccountData(response.data.data);
          setError(null);
        } else if (response.data && response.data.error) {
          setError(response.data.error.detail);
          setCompanyBankAccountData(null);
        }
      } catch (error) {
        console.error(
          "Error fetching company bank account details data:",
          error
        );
        setError("An error occurred while fetching company bank account data.");
        setCompanyBankAccountData(null);
      }
    };

    fetchCompanyBankAccountData();
  }, [userId]);

  return children(companyBankAccountData, companyBankError);
};

export default CompanyBankAccountFetcher;
