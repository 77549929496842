import axios from "../api/axios";
import useAuth from "./useAuth";

const REFRESH_TOKEN_URL = "/refresh_token";

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const isLogged = true;

  const refresh = async () => {
    try {
      const response = await axios.post(REFRESH_TOKEN_URL, "", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      const newToken = response.data.data.token;
      const newExpirationTime = response.data.data.expiresIn; // Adjust according to your response structure

      setAuth(() => {
        return { isLogged, token: newToken };
      });

      localStorage.setItem("token", newToken);
      localStorage.setItem("expirationTime", newExpirationTime);
    } catch (err) {
      console.log("Error refreshing token:", err);
      throw err;
    }
  };

  return refresh;
};

export default useRefreshToken;
